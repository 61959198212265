import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import {  IVendor, IVendorDelete, IVendorFilter, IVendorGetForm } from '../types';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class VendorService {

  constructor(
    private http: HttpClient,
    private baseService: BaseService
  ) { }

  public save(data: IVendor): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Comissao/Create`, data, options)
      .pipe(
        map((res: IVendor) => res),
        finalize(() => 'Completed!')
      )
  }

  public get(data: IVendorGetForm): Observable<IVendor> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Comissao/Details`, data, options)
      .pipe(
        map((res: IVendor) => res),
        finalize(() => 'Completed!')
      )
  }

  public list(data: IVendorFilter): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Comissao/GetAll`, data, options)
  }

  public delete(data: IVendorDelete): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Comissao/Delete`, data, options)
  }
  
  public edit(data: IVendor): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Comissao/Update`, data, options)
  }
}
