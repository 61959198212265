import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PartnerService } from 'src/app/services/partner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-partner-register',
  templateUrl: './partner-register.component.html',
  styleUrls: ['./partner-register.component.scss']
})
export class PartnerRegisterComponent implements OnInit {

  public forms = {
    main: {
      codigo: 0,
      nome: '',
      cnpj: '',
      tipo: null
    }
  }

  public buttons = {
    main: false
  }

  public partner = null;

  constructor(
    private partnerService: PartnerService,
    private router: Router
  ) { }

  ngOnInit(): void {
  }

  save() {
    if (this.validateInputs()) {
      this.buttons.main = !this.buttons.main
      this.partnerService.save(this.forms.main).subscribe(
        (res) => {
          this.buttons.main = !this.buttons.main
          this.router.navigateByUrl('/partner/list')
          Swal.fire({
            title: 'Sucesso!',
            text: res,
            icon: 'success'
          })
        },
        (err) => {
          console.log(err)
          this.buttons.main = !this.buttons.main
          Swal.fire({
            title: 'Ocorreu um erro inesperado!',
            text: err.error,
            icon: 'error'
          })
        }
      )
    }
  }

  validateInputs(): boolean {
    if (!this.forms.main.nome || this.forms.main.nome.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o nome',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.cnpj || this.forms.main.cnpj.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o cnpj',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.tipo || this.forms.main.tipo.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o tipo',
        icon: 'error'
      })
      return false
    }
    return true
  }

}
