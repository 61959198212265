import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { CarService } from 'src/app/services/car.service';
import { OwnerService } from 'src/app/services/owner.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-car-exit-stock',
  templateUrl: './car-exit-stock.component.html',
  styleUrls: ['./car-exit-stock.component.scss']
})
export class CarExitStockComponent implements OnInit {

  public forms = {
    main: {
      codigoCliente: 0,
      codigoVeiculo: 0,
      cpf_comprador: '',
      chaveNotaFiscal: '',
      valorVenda: null,
      dataVenda: ''
    }
  }

  public buttons = {
    main: false
  }

  public buyerName = ''
  public sellDate = moment().format('DD/MM/YYYY')
  public nameLoading = false

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private ownerService: OwnerService,
    private router: Router,
    private carService: CarService
  ) { }

  ngOnInit(): void {
    this.forms.main.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.route.paramMap.subscribe(paramMap => {
      this.forms.main.codigoVeiculo = parseInt(paramMap.get('id'))
    })
  }

  save() {
    if (this.validateInputs()) {
      this.buttons.main = !this.buttons.main
      this.forms.main.dataVenda = moment(this.sellDate, 'DD/MM/YYYY').toISOString().split('T')[0]
      this.carService.exitStock(this.forms.main).subscribe(
        (res) => {
          this.buttons.main = !this.buttons.main
          console.log(res)
          Swal.fire({
            title: 'Sucesso',
            text: res,
            icon: 'success'
          })
          this.router.navigateByUrl('/car/zero/list')
        },
        (err) => {
          this.buttons.main = !this.buttons.main
          console.log(err)
          Swal.fire({
            title: 'Erro!',
            text: err.error,
            icon: 'error'
          })
        }
      )
    }
  }

  validateInputs(): boolean {
    console.log(this.forms.main)
    if (!this.forms.main.cpf_comprador || this.forms.main.cpf_comprador.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o cpf do comprador',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.chaveNotaFiscal || this.forms.main.chaveNotaFiscal.length !== 44) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha a chave da nota fiscal com exatamente 44 caracteres',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.valorVenda) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o valor da venda',
        icon: 'error'
      })
      return false
    }
    if (!this.sellDate) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha a data da venda',
        icon: 'error'
      })
      return false
    }
    return true
  }

  searchOwner() {
    if (this.forms.main.cpf_comprador.length === 11) {
      const codigoCliente = this.authService.getUser().selectedClient.codigoCliente
      this.nameLoading = true
      this.ownerService.get({ codigoCliente, cpf: this.forms.main.cpf_comprador }).subscribe(
        (owner) => {
          this.nameLoading = false
          this.buyerName = owner.nome
        },
        (err) => {
          this.nameLoading = false
          console.log(err)
          Swal.fire({
            title: 'Erro!',
            text: err.error,
            icon: 'error'
          })
          this.router.navigateByUrl('/owner/register')
        }
      )
    }
  }

}
