import { HttpClientModule } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgxMaskModule, IConfig } from 'ngx-mask';
import { NgxCurrencyModule } from "ngx-currency";

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { MasterComponent } from './master/master.component';
import { HomeComponent } from './master/home/home.component';
import { MasterRoutingModule } from './master/master.routing';
import { SidebarComponent } from './master/sidebar/sidebar.component';
import { NavbarComponent } from './master/navbar/navbar.component';
import { FooterComponent } from './master/footer/footer.component';
import { ClientComponent } from './master/client/client.component';
import { GroupComponent } from './master/group/group.component';
import { PartnerComponent } from './master/partner/partner.component';
import { LoadingButtonComponent } from './components/loading-button/loading-button.component';
import { ClientRegisterComponent } from './master/register/client-register/client-register.component';
import { UserComponent } from './master/user/user.component';
import { UserRegisterComponent } from './master/register/user-register/user-register.component';
import { ZeroCarComponent } from './master/zero-car/zero-car.component';
import { CarRegisterComponent } from './master/register/car-register/car-register.component';
import { CarRegisterUsedComponent } from './master/register/car-register-used/car-register-used.component';
import { CarOwnRegisterUsedComponent } from './master/register/car-own-register-used/car-own-register-used.component';
import { CarTransferComponent } from './master/register/car-transfer/car-transfer.component';
import { CarAuthorizeTransferComponent } from './master/register/car-authorize-transfer/car-authorize-transfer.component';
import { CarAuthorizeTransferUsedComponent } from './master/register/car-authorize-transfer-used/car-authorize-transfer-used.component';
import { OwnerRegisterComponent } from './master/register/owner-register/owner-register.component';
import { OwnerComponent } from './master/owner/owner.component';
import { CarExitStockComponent } from './master/register/car-exit-stock/car-exit-stock.component';
import { LoadingPaginationButtonComponent } from './components/loading-pagination-button/loading-pagination-button.component';
import { UserChangePasswordComponent } from './master/user-change-password/user-change-password.component';
import { UserFileComponent } from './master/user-file/user-file.component';
import { CarComponent } from './master/car/car.component';
import { PermissionDirective } from './permission.directive';
import { PubSubModule } from '@shaaakh/ngx-pubsub';
import { PartnerRegisterComponent } from './master/register/partner-register/partner-register.component';
import { CarExitStockUsedComponent } from './master/register/car-exit-stock-used/car-exit-stock-used.component';
import { VendorComponent } from './master/vendor/vendor.component';
import { VendorRegisterComponent } from './master/register/vendor-register/vendor-register.component';
import { RelProdutividadeComponent } from './master/relatorios/rel-produtividade/rel-produtividade.component';

const maskConfig: Partial<IConfig> = {
  validation: false,
};
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    MasterComponent,
    HomeComponent,
    SidebarComponent,
    NavbarComponent,
    FooterComponent,
    ClientComponent,
    GroupComponent,
    PartnerComponent,
    LoadingButtonComponent,
    ClientRegisterComponent,
    UserComponent,
    UserRegisterComponent,
    ZeroCarComponent,
    CarRegisterComponent,
    CarRegisterUsedComponent,
    CarOwnRegisterUsedComponent,
    CarTransferComponent,
    CarAuthorizeTransferComponent,
    OwnerRegisterComponent,
    OwnerComponent,
    CarExitStockComponent,
    CarExitStockUsedComponent,
    LoadingPaginationButtonComponent,
    UserChangePasswordComponent,
    UserFileComponent,
    CarComponent,
    PermissionDirective,
    PartnerRegisterComponent,
    VendorRegisterComponent,
    VendorComponent,
    VendorComponent,
    RelProdutividadeComponent,
  ],
  exports: [
    PermissionDirective
  ],
  imports: [
    BrowserModule,
    RouterModule,
    AppRoutingModule,
    MasterRoutingModule,
    HttpClientModule,
    FormsModule,
    NgxMaskModule.forRoot(maskConfig),
    NgxCurrencyModule,
    PubSubModule.forRoot()
  ],
  providers: [
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
