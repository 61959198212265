import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CarService } from 'src/app/services/car.service';
import { ICancelRejection, ICar } from 'src/app/types';
import * as moment from 'moment'
import Swal from 'sweetalert2'

declare const $;

@Component({
  selector: 'app-zero-car',
  templateUrl: './zero-car.component.html',
  styleUrls: ['./zero-car.component.scss']
})
export class ZeroCarComponent implements OnInit {

  public forms = {
    filter: {
      codigoCliente: 0,
      pageSize: 10,
      page: 1,
      placa: '',
      renavam: '',
      chassi: '',
      status: 'PreCadastro',
      dataInicio: '',
      dataFim: ''
    },
    devolution: {
      codigoCliente: 0,
      codigoVeiculo: 0,
      dataDevolucao: ''
    },
    rejection: {
      codigoVeiculo: 0,
      codigoCliente: 0
    },
    pending: {
      codigoCliente: 0,
      chassi: ''
    },
    cancel: {
      codigoCliente: 0,
      codigoVeiculo: 0
    },
    cancelRejection: {
      chassi: '',
      codigoCliente: 0
    },
    check: {
      codigoCliente: 0,
      chassi: ''
    },
    consultaPdfATPV: {
      codigoCliente: 0,
      codigoVeiculo: 0
    },
  }

  public buttons = {
    main: false
  }

  public vehicles: ICar[] = []

  public devolutionDate = ''
  public dataInicio = moment().subtract(10, 'days').format('DD/MM/YYYY')
  public dataFim = moment().format('DD/MM/YYYY')
  public statuses = [
    {
      name: 'Pré Cadastro',
      value: 'PreCadastro'
    },
    {
      name: 'Estoque',
      value: 'EntradaEstoque'
    },
    {
      name: 'Rejeição montadora',
      value: 'Rejeicao'
    },
    {
      name: 'Cancelamento de entrada no estoque',
      value: 'CancelamentoEntradaEstoque'
    },
    {
      name: 'Saída do estoque',
      value: 'SaidaEstoque'
    },
    {
      name: 'Autorização de transferência',
      value: 'AutorizacaoTransferencia'
    },
    {
      name: 'Devolução da montadora',
      value: 'DevolucaoMontadora'
    },
    {
      name: 'Cancelamento de autorização de transferência',
      value: 'CancelamentoAutorizacaoTransferencia'
    },
    {
      name: 'Cancelamento de rejeição',
      value: 'CancelamentoRejeicao'
    },
    {
      name: 'Cancelamento de saída de estoque',
      value: 'CancelaSaidaEstoque'
    }
  ]

  constructor(
    private carService: CarService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.searchPendingCars()
    this.search(1)
  }

  search(page: number) {
    this.buttons.main = !this.buttons.main
    this.forms.filter.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.forms.filter.page = page
    this.forms.filter.dataInicio = moment(this.dataInicio, 'DD/MM/YYYY').toISOString().split('T')[0]
    this.forms.filter.dataFim = moment(this.dataFim, 'DD/MM/YYYY').toISOString().split('T')[0]

    if ( this.forms.filter.status == "null" )
    {
      this.forms.filter.status = "";
    }
    if (page === 1) {
      this.vehicles = [];
    }
    this.carService.listZeroCars(this.forms.filter).subscribe(
      (cars) => {
        this.buttons.main = !this.buttons.main
        cars.forEach(car => this.vehicles.push(car))
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        if ( err.status == 404)
        {  
          Swal.fire({
            title: 'Aviso!',
            text: err.error,
            icon: 'info'
          })
        }
        else
        {
          Swal.fire({
            title: 'Erro!',
            text: err.error,
            icon: 'error'
          })
        }
      }
    )
  }

  approve(codigoVeiculo: number) {
    this.router.navigate([`/car/register/${codigoVeiculo}`])
  }

  reject() {
    this.buttons.main = !this.buttons.main 
    
    this.forms.rejection.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.closeModal('modal-rejection') 
    
    this.carService.rejectZeroCar(this.forms.rejection).subscribe(
      () => {
        this.buttons.main = !this.buttons.main
        Swal.fire({
          title: 'Sucesso!',
          text: 'Rejeição do veículo realizada com sucesso',
          icon: 'success'
        })
        this.search(1)
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  giveBack() {
    this.buttons.main = !this.buttons.main
    this.forms.devolution.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.forms.devolution.dataDevolucao = moment(this.forms.devolution.dataDevolucao, 'DD/MM/YYYY').toISOString().split('T')[0]
    this.closeModal('modal-devolution')
    this.carService.devolutionZeroCar(this.forms.devolution).subscribe(
      () => {
        this.buttons.main = !this.buttons.main
        Swal.fire({
          title: 'Sucesso!',
          text: 'Devolução do veículo realizada com sucesso',
          icon: 'success'
        })
        this.search(1)
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  openModalDevolution(codigoVeiculo: number) {
    this.forms.devolution.codigoVeiculo = codigoVeiculo
    $('#modal-devolution').modal('show', { backdrop: 'fade' });
  }

  openModalRejection(codigoVeiculo: number) {
    this.forms.rejection.codigoVeiculo = codigoVeiculo  
    $('#modal-rejection').modal('show', { backdrop: 'fade' });
  }

  openModalCancelEntryStock(codigoVeiculo: number) {
    this.forms.cancel.codigoVeiculo = codigoVeiculo
    this.forms.cancel.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    $('#modal-cancel-entry-stock').modal('show', { backdrop: 'fade' })
  }

  openModalCancelExitStock(codigoVeiculo: number) {
    this.forms.cancel.codigoVeiculo = codigoVeiculo
    $('#modal-cancel-exit-stock').modal('show', { backdrop: 'fade' })
  }

  openModalCancelAuthTransfer(codigoVeiculo: number) {
    this.forms.cancel.codigoVeiculo = codigoVeiculo
    $('#modal-cancel-auth-transfer').modal('show', { backdrop: 'fade' })
  }

  openModalCancelRejection(chassi: string) {
    this.forms.cancelRejection.chassi = chassi
    $('#modal-cancel-rejection').modal('show', { backdrop: 'fade' })
  }

  openCheckStockModal() {
    $('#modal-check-stock').modal('show', { backdrop: 'fade' })
  }

  closeModal(modalId: string) {
    $(`#${modalId}`).modal('hide', { backdrop: 'fade' });
  }

  searchPendingCars() {
    this.buttons.main = !this.buttons.main
    this.forms.pending.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.carService.listPendingCars(this.forms.pending).subscribe(
      () => {
        this.buttons.main = !this.buttons.main
        this.search(1)
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  cancelAuthTransfer() {
    this.buttons.main = !this.buttons.main
    this.forms.cancel.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.carService.cancelAuthTransfer(this.forms.cancel).subscribe(
      () => {
        this.buttons.main = !this.buttons.main
        this.search(1)
        this.closeModal('modal-cancel-auth-transfer')
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
        this.closeModal('modal-cancel-auth-transfer')
      }
    )
  }

  getVehicleDate(date: Date): string {
    return moment(date).format('DD/MM/YYYY')
  }

  verifyStatus(status: String, verify: string): boolean {
    const statuses = status.split(',')
    return !!statuses.find(s => s === verify)
  }

  cancelRejection() {
    this.forms.cancelRejection.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.buttons.main = !this.buttons.main
    this.carService.cancelRejectZeroCar(this.forms.cancelRejection).subscribe(
      (res) => {
        this.buttons.main = !this.buttons.main
        console.log(res)
        Swal.fire({
          title: 'Sucesso',
          text: res,
          icon: 'success'
        })
        this.closeModal('modal-cancel-rejection')
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
        this.closeModal('modal-cancel-rejection')
      }
    )
  }

  cancelEntryStock() {
    this.buttons.main = !this.buttons.main
    this.carService.cancelEntryStock(this.forms.cancel).subscribe(
      (res) => {
        this.buttons.main = !this.buttons.main
        console.log(res)
        Swal.fire({
          title: 'Sucesso',
          text: res,
          icon: 'success'
        })
        this.closeModal('modal-cancel-entry-stock')
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
        this.closeModal('modal-cancel-entry-stock')
      }
    )
  }

  cancelExitStock() {
    this.forms.cancel.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.buttons.main = !this.buttons.main
    this.carService.cancelExitStock(this.forms.cancel).subscribe(
      (res) => {
        this.buttons.main = !this.buttons.main
        console.log(res)
        Swal.fire({
          title: 'Sucesso',
          text: res,
          icon: 'success'
        })
        this.closeModal('modal-cancel-exit-stock')
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  checkStock() {
    this.forms.check.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    if (!this.forms.check.chassi || this.forms.check.chassi.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o campo chassi',
        icon: 'error'
      })
      return
    }
    this.buttons.main = !this.buttons.main
    this.carService.checkStock(this.forms.check).subscribe(
      (res) => {
        console.log(res)
        this.buttons.main = !this.buttons.main
        this.closeModal('modal-check-stock')
        Swal.fire({
          title: 'Sucesso',
          text: res,
          icon: 'success'
        })
        this.search(1)
      },
      (err) => {
        console.log(err)
        this.buttons.main = !this.buttons.main
        this.closeModal('modal-check-stock')
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  showPdfATPV(fileBase64:string, numeroATPV:string) {
    const linkSource = 'data:application/pdf;base64,' + fileBase64;
    const downloadLink = document.createElement("a");
    const fileName = numeroATPV+".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}


  consultaPdfATPV(codigoVeiculo: number) { 
    this.buttons.main = !this.buttons.main
    this.forms.consultaPdfATPV.codigoVeiculo = codigoVeiculo
    this.forms.consultaPdfATPV.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    

    this.carService.pdfATPV(this.forms.consultaPdfATPV).subscribe(
      (res) => {
        //console.log(res)
        this.showPdfATPV(res.pdfAtpvBase64, res.numeroAtpv);
        this.buttons.main = !this.buttons.main
        this.search(1) 
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        }) 
      }
    ) 
  }

}
