import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ClientService } from 'src/app/services/client.service';
import { ICLient, IClientLoginResponse, IUser } from 'src/app/types';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  public user: IUser;
  public balance = 0

  constructor(
    private authService: AuthService,
    private router: Router,
    private clientService: ClientService,
  ) { }

  ngOnInit(): void {
    if (this.authService.isLoggedIn()) {
      this.user = this.authService.getUser()
      this.getBalance()
      setInterval(() => this.getBalance(), 300_000)
      this.authService.getPermissions(this.user.selectedClient.codigoCliente)
    }
  }

  doLogout() {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  changeClient(client: IClientLoginResponse) {
    const actualuser = this.authService.getUser()
    actualuser.selectedClient = client
    this.authService.setUser(actualuser)
    this.router.navigate(['/home'])
    this.authService.getPermissions(actualuser.selectedClient.codigoCliente)
  }

  getBalance() {
    if ( this.user.selectedClient.codigoCliente > 0)
    {
      this.clientService.getCredits({ codigo: this.user.selectedClient.codigoCliente }).subscribe(
        (res) => {
          this.balance = res
        },
        (err) => {
          console.log(err) 
        }
      )
    }
  }

}
