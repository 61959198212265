import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class BaseService {

  // public baseurl = document.URL.startsWith('http://localhost') ? 'localhost:3000' : 'http://renave.lbpinformatica.com.br'
  public baseurl = 'https://api.viarenave.com.br'
  //public baseurl = 'https://localhost:44310'

  constructor() {
  }

  getHttpOptions() {
    const options = {
      headers: new HttpHeaders(this.getHeaders()),
      withCredentials: false
    }
    return options;
  };

  getHeaders() {
    const headers = {
      'Content-Type': 'application/json',
    };
    if (this.isLoggedIn()) {
      const user = this.getItem('user')
      if (!user) {
        window.location.reload()
      }
      headers['authorization'] = `Bearer ${user.token}`;
    }
    return headers;
  }

  isLoggedIn() {
    const result = this.getItem('user');
    return result ? true : false;
  }

  logout() {
    localStorage.setItem('user', null);
  }

  getItem(key: string) {
    const itemStr = localStorage.getItem(key)
    const item = JSON.parse(itemStr)
    if (!item) {
      return null
    }
    const now = new Date()
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key)
      return null
    }
    return item
  }

  setItem(key: string, value: any, ttl: number) {
    const now = new Date()

    value.expiry = now.getTime() + ttl
    localStorage.setItem(key, JSON.stringify(value))
  }

}
