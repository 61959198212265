import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { OwnerService } from 'src/app/services/owner.service';
import { IOwner } from 'src/app/types';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-owner',
  templateUrl: './owner.component.html',
  styleUrls: ['./owner.component.scss']
})
export class OwnerComponent implements OnInit {

  public buttons = {
    main: false
  }

  public forms = {
    filter: {
      codigoCliente: 0,
      pageSize: 10,
      page: 1,
      nome: '',
      cpf: ''
    }
  }

  public owners: IOwner[] = []

  constructor(
    private ownerService: OwnerService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.search(1)
  }

  public search(page: number): void {
    this.buttons.main = !this.buttons.main
    this.forms.filter.page = page;
    this.forms.filter.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    if (page === 1) {
      this.owners = [];
    }
    this.ownerService.getAll(this.forms.filter).subscribe(
      (res) => {
        this.buttons.main = !this.buttons.main
        res.forEach(owner => this.owners.push(owner))
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  register() {
    this.router.navigate(['/owner/register'])
  }

  edit(ownerId: Number) {
    this.router.navigate([`/owner/register/${ownerId}`])
  }

  getTypePerson(type: string): string {
    if (type === 'F') {
      return 'PF'
    }
    return 'PJ'
  }
  
  getTypeMask(type: string): string {
    if (type === 'F') {
      return '000.000.000-00'
    }
    return '00.000.000/0000-00'
  }
}
