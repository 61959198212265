import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CarService } from 'src/app/services/car.service';
import { IAvailabilityVehicle, ICancelRejection, ICar } from 'src/app/types';
import * as moment from 'moment'
import Swal from 'sweetalert2'

declare const $;

@Component({
  selector: 'app-car',
  templateUrl: './car.component.html',
  styleUrls: ['./car.component.scss']
})
export class CarComponent implements OnInit {

  public forms = {
    filter: {
      codigoCliente: 0,
      pageSize: 10,
      page: 1,
      placa: '',
      renavam: '',
      chassi: '',
      status: 'EntradaEstoque',
      dataInicio: '',
      dataFim: ''
    },
    devolution: {
      codigoCliente: 0,
      codigoVeiculo: 0,
      dataDevolucao: ''
    },
    rejection: {
      codigoVeiculo: 0,
      codigoCliente: 0
    },
    pending: {
      codigoCliente: 0,
      chassi: ''
    },
    cancel: {
      codigoCliente: 0,
      codigoVeiculo: 0
    },
    cancelRejection: {
      chassi: '',
      codigoCliente: 0
    },
    check: {
      codigoCliente: 0,
      chassi: ''
    },
    consultaPdfATPV: {
      codigoCliente: 0,
      codigoVeiculo: 0
    },
    availability: {
      codigoCliente: 0,
      numeroCrv: '',
      placa: '',
      renavam: '',
      tipoCrv: ''
    },
    sendNF: {
      codigoCliente: 0,
      codigoVeiculo: 0,
      chaveNotaFiscal: '',
      tipo: '',
    },
    sendATPV: {
      codigoCliente: 0,
      codigoVeiculo: 0, 
      atpvPapelMoedaProprioPunho:false,
      atpveAssinadoDeProprioPunho:false,      
      fileBase64:'',
      atpvPapelMoedaProprioPunhoAssinadoBase64:'',
      atpveAssinadoDeProprioPunhoBase64:'', 
    },
  }

  public buttons = {
    main: false
  }

  public vehicles: ICar[] = []
  public vehiclesAvailable: IAvailabilityVehicle[] = []

  public devolutionDate = ''
  public dataInicio = moment().subtract(10, 'days').format('DD/MM/YYYY')
  public dataFim = moment().format('DD/MM/YYYY')
  public statuses = [
    
    {
      name: 'Estoque',
      value: 'EntradaEstoque'
    }, 
    {
      name: 'Cancelamento de entrada no estoque',
      value: 'CancelamentoEntradaEstoque'
    },
    {
      name: 'Saída do estoque',
      value: 'SaidaEstoque'
    },
    {
      name: 'Autorização de transferência',
      value: 'AutorizacaoTransferencia'
    }, 
    {
      name: 'Cancelamento de autorização de transferência',
      value: 'CancelamentoAutorizacaoTransferencia'
    },
    {
      name: 'Cancelamento de rejeição',
      value: 'CancelamentoRejeicao'
    },
    {
      name: 'Cancelamento de saída de estoque',
      value: 'CancelaSaidaEstoque'
    }
  ]

  public fileATPV = ''

  constructor(
    private carService: CarService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    // this.searchPendingCars()
    this.search(1)
  }

  search(page: number) {
    this.buttons.main = !this.buttons.main
    this.forms.filter.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.forms.filter.page = page
    this.forms.filter.dataInicio = moment(this.dataInicio, 'DD/MM/YYYY').toISOString().split('T')[0]
    this.forms.filter.dataFim = moment(this.dataFim, 'DD/MM/YYYY').toISOString().split('T')[0]

    if ( this.forms.filter.status == "null" )
    {
      this.forms.filter.status = "";
    }
    if (page === 1) {
      this.vehicles = [];
    }
    this.carService.listCars(this.forms.filter).subscribe(
      (cars) => {
        this.buttons.main = !this.buttons.main
        cars.forEach(car => this.vehicles.push(car))
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        if ( err.status == 404)
        {  
          Swal.fire({
            title: 'Aviso!',
            text: err.error,
            icon: 'info'
          })
        }
        else
        {
          Swal.fire({
            title: 'Erro!',
            text: err.error,
            icon: 'error'
          })
        }
      }
    )
  }

  registerCar() {
    this.router.navigate([`/car/register-used`])
  }
  registerOwnCar() {
    this.router.navigate([`/car/own-register-used`])
  }
  reject() {
    this.buttons.main = !this.buttons.main 
    
    this.forms.rejection.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.closeModal('modal-rejection') 
    
    this.carService.rejectZeroCar(this.forms.rejection).subscribe(
      () => {
        this.buttons.main = !this.buttons.main
        Swal.fire({
          title: 'Sucesso!',
          text: 'Rejeição do veículo realizada com sucesso',
          icon: 'success'
        })
        this.search(1)
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  giveBack() {
    this.buttons.main = !this.buttons.main
    this.forms.devolution.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.forms.devolution.dataDevolucao = moment(this.forms.devolution.dataDevolucao, 'DD/MM/YYYY').toISOString().split('T')[0]
    this.closeModal('modal-devolution')
    this.carService.devolutionZeroCar(this.forms.devolution).subscribe(
      () => {
        this.buttons.main = !this.buttons.main
        Swal.fire({
          title: 'Sucesso!',
          text: 'Devolução do veículo realizada com sucesso',
          icon: 'success'
        })
        this.search(1)
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  openModalDevolution(codigoVeiculo: number) {
    this.forms.devolution.codigoVeiculo = codigoVeiculo
    $('#modal-devolution').modal('show', { backdrop: 'fade' });
  }

  openModalRejection(codigoVeiculo: number) {
    this.forms.rejection.codigoVeiculo = codigoVeiculo  
    $('#modal-rejection').modal('show', { backdrop: 'fade' });
  }

  openModalCancelEntryStock(codigoVeiculo: number) {
    this.forms.cancel.codigoVeiculo = codigoVeiculo
    this.forms.cancel.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    $('#modal-cancel-entry-stock').modal('show', { backdrop: 'fade' })
  }

  openModalCancelExitStock(codigoVeiculo: number) {
    this.forms.cancel.codigoVeiculo = codigoVeiculo
    $('#modal-cancel-exit-stock').modal('show', { backdrop: 'fade' })
  }

  openModalCancelAuthTransfer(codigoVeiculo: number) {
    this.forms.cancel.codigoVeiculo = codigoVeiculo
    $('#modal-cancel-auth-transfer').modal('show', { backdrop: 'fade' })
  }

  openModalCancelRejection(chassi: string) {
    this.forms.cancelRejection.chassi = chassi
    $('#modal-cancel-rejection').modal('show', { backdrop: 'fade' })
  }

  openCheckStockModal() {
    $('#modal-check-stock').modal('show', { backdrop: 'fade' })
  }

  openAvailabilityStockModal() {
    $('#modal-check-availability').modal('show', { backdrop: 'fade' })
  }
  openAvailabilityDisplayModal() {
    $('#modal-display-availability').modal('show', { backdrop: 'fade' })
  } 

  openSendNFModal(codigoVeiculo: number) {
    this.forms.sendNF.codigoVeiculo = codigoVeiculo;
    this.forms.sendNF.tipo = "COMPRA";
    $('#modal-send-nf').modal('show', { backdrop: 'fade' })
  }
  
  openSendExitNFModal(codigoVeiculo: number) {
    this.forms.sendNF.codigoVeiculo = codigoVeiculo;
    this.forms.sendNF.tipo = "VENDA";
    $('#modal-send-nf').modal('show', { backdrop: 'fade' })
  }
  openSendATPVModal(codigoVeiculo: number) {
    this.forms.sendATPV.codigoVeiculo = codigoVeiculo;
    this.forms.sendATPV.atpvPapelMoedaProprioPunho = false;
    this.forms.sendATPV.atpveAssinadoDeProprioPunho = false;
    this.forms.sendATPV.fileBase64 = "";
    this.fileATPV = "";
    $('#modal-send-signature-atpv').modal('show', { backdrop: 'fade' })
  }

  closeModal(modalId: string) {
    $(`#${modalId}`).modal('hide', { backdrop: 'fade' });
  }

  searchPendingCars() {
    this.buttons.main = !this.buttons.main
    this.forms.pending.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.carService.listPendingCars(this.forms.pending).subscribe(
      () => {
        this.buttons.main = !this.buttons.main
        this.search(1)
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        //console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  cancelAuthTransfer() {
    this.buttons.main = !this.buttons.main
    this.forms.cancel.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.carService.cancelAuthTransfer(this.forms.cancel).subscribe(
      () => {
        this.buttons.main = !this.buttons.main
        this.search(1)
        this.closeModal('modal-cancel-auth-transfer')
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        //console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
        this.closeModal('modal-cancel-auth-transfer')
      }
    )
  }

  getVehicleDate(date: Date): string {
    return moment(date).format('DD/MM/YYYY')
  }

  verifyStatus(status: String, verify: string): boolean {
    const statuses = status.split(',')
    return !!statuses.find(s => s === verify)
  }
 
  cancelEntryStock() {
    this.buttons.main = !this.buttons.main
    this.carService.cancelEntryStock(this.forms.cancel).subscribe(
      (res) => {
        this.buttons.main = !this.buttons.main
        //console.log(res)
        Swal.fire({
          title: 'Sucesso',
          text: res,
          icon: 'success'
        })
        this.closeModal('modal-cancel-entry-stock')
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
        this.closeModal('modal-cancel-entry-stock')
      }
    )
  }

  cancelExitStock() {
    this.forms.cancel.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.buttons.main = !this.buttons.main
    this.carService.cancelExitStockUsed(this.forms.cancel).subscribe(
      (res) => {
        this.buttons.main = !this.buttons.main
        //console.log(res)
        Swal.fire({
          title: 'Sucesso',
          text: res,
          icon: 'success'
        })
        this.closeModal('modal-cancel-exit-stock')
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  checkStock() {
    this.forms.check.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    if (!this.forms.check.chassi || this.forms.check.chassi.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o campo chassi',
        icon: 'error'
      })
      return
    }
    this.buttons.main = !this.buttons.main
    this.carService.checkStock(this.forms.check).subscribe(
      (res) => {
        //console.log(res)
        this.buttons.main = !this.buttons.main
        this.closeModal('modal-check-stock')
        Swal.fire({
          title: 'Sucesso',
          text: res,
          icon: 'success'
        })
        this.search(1)
      },
      (err) => {
        console.log(err)
        this.buttons.main = !this.buttons.main
        this.closeModal('modal-check-stock')
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }
 


  consultaPdfATPV(codigoVeiculo: number) { 
    this.buttons.main = !this.buttons.main
    this.forms.consultaPdfATPV.codigoVeiculo = codigoVeiculo
    this.forms.consultaPdfATPV.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    

    this.carService.pdfATPV(this.forms.consultaPdfATPV).subscribe(
      (res) => {
        //console.log(res)
        this.showPdf(res.pdfAtpvBase64, 'atpv-'+res.numeroAtpv);
        this.buttons.main = !this.buttons.main
        this.search(1) 
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        }) 
      }
    ) 
  }
  
  
  consultaPdfCRLVE(codigoVeiculo: number) { 
    this.buttons.main = !this.buttons.main
    this.forms.consultaPdfATPV.codigoVeiculo = codigoVeiculo
    this.forms.consultaPdfATPV.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    
    var horaAtual = new Date().getTime().toString();
    this.carService.pdfCRLVE(this.forms.consultaPdfATPV).subscribe(
      (res) => {
        //console.log(res)
        this.showPdf(res.pdfBase64, 'crlv_'+horaAtual);
        this.buttons.main = !this.buttons.main
        this.search(1) 
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        }) 
      }
    ) 
  }

  
  consultaPdfCRLVESeguranca(codigoVeiculo: number) { 
    this.buttons.main = !this.buttons.main
    this.forms.consultaPdfATPV.codigoVeiculo = codigoVeiculo
    this.forms.consultaPdfATPV.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    
    var horaAtual = new Date().getTime().toString();
    this.carService.pdfCRLVESeguranca(this.forms.consultaPdfATPV).subscribe(
      (res) => { 
        this.showPdf(res.pdfCodigoSegurancaCrvBase64, 'crlv_seg_'+horaAtual);
        this.buttons.main = !this.buttons.main
        this.search(1) 
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        }) 
      }
    ) 
  }

  availabilityStock() {
    this.forms.availability.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    if (!this.forms.availability.tipoCrv || this.forms.availability.tipoCrv.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o campo tipo CRV',
        icon: 'error'
      })
      return
    }
    if (!this.forms.availability.placa || this.forms.availability.placa.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o campo placa',
        icon: 'error'
      })
      return
    }if (!this.forms.availability.numeroCrv || this.forms.availability.numeroCrv.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o campo numero CRV',
        icon: 'error'
      })
      return
    }if (!this.forms.availability.renavam || this.forms.availability.renavam.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o campo renavam',
        icon: 'error'
      })
      return
    }
    this.buttons.main = !this.buttons.main
    
    this.carService.availabilityStock(this.forms.availability).subscribe(
      (res) => {        
        this.vehiclesAvailable = [];
        console.log(res)
        res.forEach(r => this.vehiclesAvailable.push(r))

        this.buttons.main = !this.buttons.main
        this.closeModal('modal-check-availability')
        this.openAvailabilityDisplayModal();
       /* this.closeModal('modal-check-availability')
        Swal.fire({
          title: 'Sucesso',
          text:  res,
          icon: 'success'
        })
        this.search(1)*/
      },
      (err) => {
        console.log(err)
        this.buttons.main = !this.buttons.main
        this.closeModal('modal-check-availability')
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  
  sendNF() {
    this.forms.sendNF.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    if (!this.forms.sendNF.chaveNotaFiscal || this.forms.sendNF.chaveNotaFiscal.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o campo nota fiscal',
        icon: 'error'
      })
      return
    } 
    
    this.buttons.main = !this.buttons.main
    this.carService.sendNF(this.forms.sendNF).subscribe(
      (res) => {
        console.log(res)
        this.buttons.main = !this.buttons.main
        this.closeModal('modal-send-nf')
        Swal.fire({
          title: 'Sucesso',
          text: res,
          icon: 'success'
        })
        this.search(1)
      },
      (err) => {
        console.log(err)
        this.buttons.main = !this.buttons.main
        this.closeModal('modal-send-nf')
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  

  showPdf(fileBase64:string, numero:string) {
    const linkSource = 'data:application/pdf;base64,' + fileBase64;
    const downloadLink = document.createElement("a");
    const fileName = numero+".pdf";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}


  termEntry(codigoVeiculo: number) { 
    this.buttons.main = !this.buttons.main
    this.forms.consultaPdfATPV.codigoVeiculo = codigoVeiculo
    this.forms.consultaPdfATPV.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    

    this.carService.termEntry(this.forms.consultaPdfATPV).subscribe(
      (res) => {
        //console.log(res)
        this.showPdf(res.pdfBase64, res.numeroTermoEntradaEstoque);
        this.buttons.main = !this.buttons.main
        this.search(1) 
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        }) 
      }
    ) 
  }
  
  termExit(codigoVeiculo: number) { 
    this.buttons.main = !this.buttons.main
    this.forms.consultaPdfATPV.codigoVeiculo = codigoVeiculo
    this.forms.consultaPdfATPV.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    

    this.carService.termExit(this.forms.consultaPdfATPV).subscribe(
      (res) => {
        //console.log(res)
        this.showPdf(res.pdfBase64, 'termo-saida-'+res.numeroTermoSaidaEstoque);
        this.buttons.main = !this.buttons.main
        this.search(1) 
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        }) 
      }
    ) 
  } 

  
  
  getFileName() {
    if (this.fileATPV) {
      return this.fileATPV;
    }
    return 'Selecione um arquivo...';
  }
  fileChange(event) {
    if (event.target.files.length > 0) {
      this.fileATPV = event.target.files[0].name;
      const file = event.target.files[0];
      this.getBase64(file).then(
        (base64: any) => {
          this.forms.sendATPV.fileBase64 = base64.split('base64,')[1]
          console.log(base64)
        }
      )
    }
  }
  
  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  
  sendATPV() {
    this.forms.sendATPV.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    if (!this.forms.sendATPV.fileBase64 || this.forms.sendATPV.fileBase64.length === 0)  
    {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o tipo do ATPV',
        icon: 'error'
      })
      return
    } 
    if (this.forms.sendATPV.atpvPapelMoedaProprioPunho)
    {
      this.forms.sendATPV.atpvPapelMoedaProprioPunhoAssinadoBase64 = this.forms.sendATPV.fileBase64;
    }else if (this.forms.sendATPV.atpveAssinadoDeProprioPunho)
    {
      this.forms.sendATPV.atpveAssinadoDeProprioPunhoBase64 = this.forms.sendATPV.fileBase64;
    }
    
    this.buttons.main = !this.buttons.main
    this.carService.sendATPVASignature(this.forms.sendATPV).subscribe(
      (res) => {
        console.log(res)
        this.buttons.main = !this.buttons.main
        this.closeModal('modal-send-signature-atpv')
        Swal.fire({
          title: 'Sucesso',
          text: res,
          icon: 'success'
        })
        this.search(1)
      },
      (err) => {
        console.log(err)
        this.buttons.main = !this.buttons.main
        this.closeModal('modal-send-signature-atpv')
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  
  consultaATPVAssinatura(codigoVeiculo: number) { 
    this.buttons.main = !this.buttons.main
    this.forms.consultaPdfATPV.codigoVeiculo = codigoVeiculo
    this.forms.consultaPdfATPV.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    
    // var horaAtual = new Date().getTime().toString();
    // this.carService.searchATPVSignature(this.forms.consultaPdfATPV).subscribe(
    //   (res) => {
    //     //console.log(res)
    //     this.showPdf(res.pdfBase64, horaAtual);
    //     this.buttons.main = !this.buttons.main
    //     this.search(1) 
    //   },
    //   (err) => {
    //     this.buttons.main = !this.buttons.main
    //     console.log(err)
    //     Swal.fire({
    //       title: 'Erro!',
    //       text: err.error,
    //       icon: 'error'
    //     }) 
    //   }
    // ) 
  }

  
}
