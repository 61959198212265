import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CarService } from 'src/app/services/car.service';
import * as moment from 'moment'
import { AuthService } from 'src/app/services/auth.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-car-transfer',
  templateUrl: './car-transfer.component.html',
  styleUrls: ['./car-transfer.component.scss']
})
export class CarTransferComponent implements OnInit {

  public forms = {
    main: {
      codigoCliente: 0,
      chaveNotaFiscal: '',
      dataHoraMedicaoHodometro: '',
      dataTransferenciaEstoque: '',
      idAutorizacao: null,
      quilometragemHodometro: 0,
      valorCompra: null
    }
  }

  public buttons = {
    main: false
  }

  public dataTransferenciaEstoque = ''
  public dataHoraMedicaoHodometro = ''

  constructor(
    private carService: CarService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.forms.main.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
  }

  save() {
    if (!this.forms.main.chaveNotaFiscal || this.forms.main.chaveNotaFiscal.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha a chave da nota fiscal',
        icon: 'error'
      })
      return
    }
    if (!this.forms.main.dataHoraMedicaoHodometro || this.forms.main.dataHoraMedicaoHodometro.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha a data e hora de medição do hodômetro',
        icon: 'error'
      })
      return
    }
    if (!this.forms.main.dataTransferenciaEstoque || this.forms.main.dataTransferenciaEstoque.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha a data de transferência',
        icon: 'error'
      })
      return
    }
    if (!this.forms.main.idAutorizacao || this.forms.main.idAutorizacao.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o código da autorização',
        icon: 'error'
      })
      return
    }
    if (!this.forms.main.valorCompra || this.forms.main.valorCompra.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o valor da nota',
        icon: 'error'
      })
      return
    }
    this.buttons.main = !this.buttons.main
    this.forms.main.dataTransferenciaEstoque = moment(this.dataTransferenciaEstoque, 'DD/MM/YYYY').toISOString().split('T')[0]
    this.forms.main.dataHoraMedicaoHodometro = moment(this.dataHoraMedicaoHodometro, 'DD/MM/YYYY hh:mm').format('YYYY-MM-DDTHH:mm:ss')
    this.carService.confirmTransfer(this.forms.main).subscribe(
      () => {
        this.buttons.main = !this.buttons.main
        Swal.fire({
          title: 'Sucesso!',
          text: 'Confirmação de transferência realizada com sucesso',
          icon: 'success'
        })
        this.router.navigateByUrl('/car/zero/list')
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

}
