import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { ClientService } from 'src/app/services/client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-change-password',
  templateUrl: './user-change-password.component.html',
  styleUrls: ['./user-change-password.component.scss']
})
export class UserChangePasswordComponent implements OnInit {

  public forms = {
    main: {
      senhaAtual: '',
      senhaNova: '',
      codigo: this.authService.getUser().id,
      codigoCliente: this.authService.getUser().selectedClient.codigoCliente
    }
  }

  public buttons = {
    main: false
  }

  public name = this.authService.getUser().nome
  public confirmPassword = ''

  constructor(
    private authService: AuthService,
    private clientService: ClientService
  ) { }

  ngOnInit(): void {
  }

  save() {
    if (this.validatePassword()) {
      this.buttons.main = !this.buttons.main
      this.clientService.updatePassword(this.forms.main).subscribe(
        (res) => {
          this.buttons.main = !this.buttons.main
          console.log(res)
          Swal.fire({
            title: 'Sucesso',
            text: res,
            icon: 'success'
          })
        },
        (err) => {
          console.log(err)
          this.buttons.main = !this.buttons.main
          Swal.fire({
            title: 'Erro',
            text: err.error,
            icon: 'error'
          })
        }
      )
    }
  }

  validatePassword(): boolean {
    if (!this.forms.main.senhaNova || this.forms.main.senhaNova.length === 0 || (this.forms.main.senhaNova !== this.confirmPassword)) {
      Swal.fire({
        title: 'Erro',
        text: 'A nova senha e a senha de confirmação estão diferentes!',
        icon: 'error'
      })
      return false
    }
    return true
  }
}
