import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ClientService } from 'src/app/services/client.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-file',
  templateUrl: './user-file.component.html',
  styleUrls: ['./user-file.component.scss']
})
export class UserFileComponent implements OnInit {

  public forms = {
    main: {
      codigoCliente: this.authService.getUser().selectedClient.codigoCliente,
      certificado64: null,
      password: ''
    }
  }

  public buttons = {
    main: false
  }

  public certificadoName = ''

  constructor(
    private authService: AuthService,
    private clientService: ClientService,
    private router: Router,
  ) { }

  ngOnInit(): void {
  }

  getFileName() {
    if (this.certificadoName) {
      return this.certificadoName;
    }
    return 'Selecione um arquivo...';
  }

  fileChange(event) {
    if (event.target.files.length > 0) {
      this.certificadoName = event.target.files[0].name;
      const file = event.target.files[0];
      this.getBase64(file).then(
        (base64: any) => {
          this.forms.main.certificado64 = base64.split('base64,')[1]
          console.log(base64)
        }
      )
    }
  }

  getBase64(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });
  }

  save() {
    if (!this.forms.main.certificado64) {
      Swal.fire({
        title: 'Erro!',
        text: 'Faça o upload do certificado!',
        icon: 'error'
      })
      return
    }
    if (!this.forms.main.password) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha a senha do certificado!',
        icon: 'error'
      })
      return
    }
    this.buttons.main = !this.buttons.main
    this.clientService.saveCertificate(this.forms.main).subscribe(
      (res) => {
        this.buttons.main = !this.buttons.main
        console.log(res)
        this.router.navigateByUrl('/home')
        Swal.fire({
          title: 'Sucesso!',
          text: res,
          icon: 'success'
        })
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }
}
