<div class="page-title">
  <div class="title-env">
    <h1 class="title">Saída do estoque</h1>
  </div>
</div>

<div class="panel panel-default panel-border" *permission="['VeiculoUsado-SaidaEstoque']">
  <div class="panel-heading">
    Informações
  </div>
  <form role="form">
    <div class="panel-body">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="cpf">CPF/CNPJ do comprador</label>
            <input type="text" class="form-control" (blur)="searchOwner()" name="cpf" id="cpf"
              [(ngModel)]="forms.main.cpf_comprador"  placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <span *ngIf="nameLoading" class="spinner-border spinner-border-sm" style="margin-left: 10px;" role="status"
              aria-hidden="true"></span>
            <label for="buyerName" style="color: #ababab;">Nome do comprador</label>
            <input type="text" disabled [(ngModel)]="buyerName" class="form-control" id="buyerName" name="buyerName"
              placeholder="Digite aqui">
          </div>
        </div> 
        <div class="col-12 col-sm-2">
          <div class="form-group">
            <label for="txbplaca">Placa</label>
            <input type="text" disabled [(ngModel)]="forms.main.placa" class="form-control" id="txbplaca"  
              name="txbplaca" placeholder="Digite aqui" >
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="txbRenavam">Renavam</label>
            <input type="text" disabled [(ngModel)]="forms.main.renavam" class="form-control" id="txbRenavam"  
              name="txbrenavam" placeholder="Digite aqui" >
          </div>
        </div>
        <div class="col-12 col-sm-3">
          <div class="form-group">
            <label for="NumeroCRV">CRV</label>
            <input type="text"  [(ngModel)]="forms.main.numeroCRV" class="form-control" id="NumeroCRV" maxlength="12"
              name="NumeroCRV" placeholder="Digite aqui" >
          </div>
        </div>
        <div class="col-12 col-sm-3">
          <div class="form-group">
            <label for="CodigoSegurancaCRV">Código SegurançaCRV</label>
            <input type="text" [(ngModel)]="forms.main.codigoSegurancaCRV" class="form-control" id="CodigoSegurancaCRV" maxlength="11"
              name="CodigoSegurancaCRV" placeholder="Digite aqui" >
          </div>
        </div> 

        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="sell-value">Valor da venda</label>
            <input type="text" [(ngModel)]="forms.main.valorVenda" class="form-control" id="sell-value"
              name="sell-value" placeholder="Digite aqui" currencyMask
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="sell-date">Data</label>
            <input type="text" [(ngModel)]="sellDate" class="form-control" id="sell-date" name="sell-date"
              placeholder="Digite aqui" mask="00/00/0000">
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <loading-button (click)="save()" [btnClass]="'btn btn-primary'" [isLoading]="buttons.main">
        Salvar
      </loading-button>
    </div>
  </form>
</div>