<div class="page-title">
  <div class="title-env">
    <h1 class="title">Meus Associados | Cadastrar Associado</h1>
  </div>
</div>

<div class="panel panel-default panel-border">
  <div class="panel-heading">
    Informações do associado
  </div>
  <form role="form">
    <div class="panel-body">
      <div class="row">
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="name">Nome <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="name" [(ngModel)]="forms.main.nome" id="name"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="cpf">CNPJ<span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="cpf" [(ngModel)]="forms.main.cnpj" mask="00.000.000/0000-00"
              id="cpf" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="tipo">Tipo <span class="mandatory">*</span></label>
            <select class="form-control" name="tipo" id="tipo" [(ngModel)]="forms.main.tipo">
              <option value="null" selected>Selecione uma opção</option>
              <option value="A">Assoveba</option>
              <option value="S">SinCovd</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <loading-button (click)="save()" [btnClass]="'btn btn-primary'" [isLoading]="buttons.main">
        Salvar
      </loading-button>
    </div>
  </form>
</div>