import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { PartnerService } from 'src/app/services/partner.service';
import { IPartnerFilter } from 'src/app/types';
import Swal from 'sweetalert2';

declare const $;

@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.scss']
})
export class PartnerComponent implements OnInit {

  public forms = {
    filter: {
      codigoCliente: this.authService.getUser().selectedClient.codigoCliente,
      pageSize: 10,
      page: 1,
      descricao: '',
      tipo: 'A'
    },
    delete: {
      codigo: 0
    }
  }

  public buttons = {
    main: false
  }

  public partners = []

  constructor(
    private authService: AuthService,
    private partnerService: PartnerService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.search(1)
  }

  search(page: number) {
    this.buttons.main = !this.buttons.main
    this.forms.filter.page = page
    if (page === 1) {
      this.partners = []
    }
    this.partnerService.list(this.forms.filter).subscribe(
      (res) => {
        this.buttons.main = !this.buttons.main
        this.partners.push(...res)
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  openModalDelete(codigoParceiro: number) {
    this.forms.delete.codigo = codigoParceiro
    $('#modal-delete').modal('show', { backdrop: 'fade' })
  }

  delete() {
    this.buttons.main = !this.buttons.main
    this.partnerService.delete(this.forms.delete).subscribe(
      (res) => {
        this.buttons.main = !this.buttons.main
        this.search(1)
        this.closeModal('modal-delete')
        Swal.fire({
          title: 'Sucesso',
          text: res,
          icon: 'success'
        })
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
        this.closeModal('modal-delete')
      }
    )
  }

  register() {
    this.router.navigate(['/partner/register'])
  }

  closeModal(modalId: string) {
    $(`#${modalId}`).modal('hide', { backdrop: 'fade' });
  }

  getPartnerType(type: 'A' | 'S') {
    if (type === 'A') {
      return 'Assoveba'
    } else {
      return 'SinCovd'
    }
  }
}
