import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { IOwner, IOwnerFilter, IOwnerGetForm } from '../types';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class OwnerService {

  constructor(
    private http: HttpClient,
    private baseService: BaseService
  ) { }

  public save(data: IOwner): Observable<IOwner> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Proprietario/Create`, data, options)
      .pipe(
        map((res: IOwner) => res),
        finalize(() => 'Completed!')
      )
  }

  public get(data: IOwnerGetForm): Observable<IOwner> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Proprietario/Details`, data, options)
      .pipe(
        map((res: IOwner) => res),
        finalize(() => 'Completed!')
      )
  }

  public getAll(data: IOwnerFilter): Observable<IOwner[]> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Proprietario/GetAll`, data, options)
      .pipe(
        map((res: IOwner[]) => res),
        finalize(() => 'Completed!')
      )
  }

  public edit(data: IOwner): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Proprietario/Update`, data, options)
  }
}
