import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { IAuthorization, IForgotForm, ILoginForm, ILoginResponse, ILoginTokenForm, IUser } from '../types';
import { PubSubService } from '@shaaakh/ngx-pubsub';

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  constructor(
    private baseService: BaseService,
    private http: HttpClient,
    private pubSub: PubSubService
  ) { }

  public allowedActions = []

  login(data: ILoginForm): Observable<ILoginResponse> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Authenticate/Login`, JSON.stringify(data), options)
      .pipe(
        map((res: ILoginResponse) => res),
        finalize(() => 'Completed!')
      );
  }
  loginToken(data: ILoginTokenForm): Observable<ILoginResponse> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Authenticate/LoginToken`, JSON.stringify(data), options)
      .pipe(
        map((res: ILoginResponse) => res),
        finalize(() => 'Completed!')
      );
  }

  forgotPassword(data: IForgotForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Authenticate/Esqueceu`, JSON.stringify(data), options)
  }

  getPermissions(clientId: Number) {
    const options = this.baseService.getHttpOptions()
    this.http.post(`${this.baseService.baseurl}/Acesso/Autorizados`, { codigoCliente: clientId }, options).subscribe(
      (res: IAuthorization[]) => {
        this.allowedActions = res.map(permission => permission.menu.toLowerCase())
        this.pubSub.$pub('onPermissionChange', this.allowedActions)
      },
      (err) => {
        console.log(err)
      }
    )
  }

  isLoggedIn() {
    return this.baseService.isLoggedIn();
  }

  logout() {
    this.baseService.logout();
  }

  setUser(user: IUser) {
    this.baseService.setItem('user', user, 86_400_000);
  }

  getUser(): IUser {
    return this.baseService.getItem('user');
  }
}
