import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'loading-button',
  templateUrl: './loading-button.component.html',
  styleUrls: ['./loading-button.component.scss']
})
export class LoadingButtonComponent implements OnInit {
  @Input() isLoading = false;
  @Input() btnClass = 'btn';

  constructor() { }

  ngOnInit(): void {
  }

}
