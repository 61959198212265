<div class="page-title">
  <div class="row">
    <div class="col-6">
      <div class="title-env">
        <h1 class="title">Parceiro</h1>
      </div>
    </div>
    <div class="col-6 text-right" style="padding-right: 50px;" *permission="['Vendedor-Cadastrar']">
      <button class="btn btn-primary" (click)="register()">Cadastrar parceiro</button>
    </div>
  </div>
</div>

<div class="panel panel-default panel-border">
  <div class="panel-heading">
    Filtrar parceiros
  </div>
  <div class="panel-body">
    <form role="form">
      <div class="row">
        <div class="col-sm-6 col-12">
          <div class="form-group">
            <label for="name">Nome</label>
            <input type="text" class="form-control" name="name" [(ngModel)]="forms.filter.nome" id="name" maxlength="100"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-sm-6 col-12">
          <div class="form-group">
            <label for="cnpj">CNPJ</label>
            <input type="text" class="form-control" name="cnpj" [(ngModel)]="forms.filter.cnpj" id="cnpj"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12">
          <loading-button (click)="search(1)" class="d-flex justify-content-end" [btnClass]="'btn btn-primary'"
            [isLoading]="buttons.main">
            Filtrar</loading-button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="panel-body" *permission="['Vendedor-Listar']">
  <div class="row">
    <div class="col-12">
      <table class="table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>Cnpj</th>
            <th [hidden]="!isAdmin" >Parceiro </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let vendor of vendors">
            <td>{{vendor.nome}}</td>
            <td>{{vendor.cnpj | mask:getTypeMask()}}</td>
            <td [hidden]="!isAdmin">{{vendor.nomeUsuario}}</td>            
            <td class="d-flex justify-content-center">
              <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                  Ações
                </button>
                <ul class="dropdown-menu" role="menu">
                  <li *permission="['Vendedor-Alterar']">
                    <a class="dropdown-item" (click)="edit(vendor.codigo)">Editar</a>
                  </li>
                  <li *permission="['Vendedor-Cadastrar']">
                    <a class="dropdown-item" (click)="register()">Cadastrar Parceiro</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-12" *ngIf="vendors.length > 0">
        <app-loading-pagination-button *ngIf="vendors.length > 0" [isLoading]="buttons.main"
          (click)="search(forms.filter.page + 1)">
        </app-loading-pagination-button>
      </div>
    </div>
  </div>
</div>