import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { IPartnerDelete, IPartnerFilter, IPartnerForm } from '../types';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class PartnerService {

  constructor(
    private http: HttpClient,
    private baseService: BaseService
  ) { }

  public save(data: IPartnerForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Parceiro/Create`, data, options)
      .pipe(
        map((res: IPartnerForm) => res),
        finalize(() => 'Completed!')
      )
  }

  public list(data: IPartnerFilter): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Parceiro/GetAll`, data, options)
  }

  public delete(data: IPartnerDelete): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Parceiro/Delete`, data, options)
  }
}
