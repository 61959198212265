<div class="page-title">
  <div class="row">
    <div class="col-6">
      <div class="title-env">
        <h1 class="title">Relatório de Produtividade</h1>
      </div>
    </div> 
  </div>
</div>

<div class="panel panel-default panel-border">  
  <div class="panel-body">
    <form role="form">
      <div class="row">        
        <div class="col-sm-6 col-12">
          <div class="form-group">
            <label for="period-start">Período inicial</label>
            <input type="text" class="form-control" name="period-start" [(ngModel)]="dataInicial" id="period-start"
              placeholder="Digite aqui" mask="00/00/0000">
          </div>
        </div>
        <div class="col-sm-6 col-12">
          <div class="form-group">
            <label for="period-end">Período final</label>
            <input type="text" class="form-control" name="period-end" [(ngModel)]="dataFinal" id="period-end"
              placeholder="Digite aqui" mask="00/00/0000">
          </div>
        </div>
        
        <div class="col-12">
          <div class="d-flex justify-content-end">
            <loading-button [btnClass]="'btn btn-primary'" (click)="relatorio()" style="margin-right: 18px;"
              [isLoading]="buttons.main">
              Gerar
            </loading-button>  
          </div>
        </div>
      </div>
    </form>
  </div>
</div>