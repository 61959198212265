import { Directive, TemplateRef, Input, ViewContainerRef, OnInit, Injectable } from '@angular/core';
import { PubSubService } from '@shaaakh/ngx-pubsub';
import { AuthService } from './services/auth.service';

@Directive({
  selector: '[permission]',
})
export class PermissionDirective implements OnInit {

  @Input() permission: string[];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private authService: AuthService,
    private pubSub: PubSubService
  ) {
  }

  ngOnInit() {
    this.pubSub.$sub('onPermissionChange', () => this.verify())
    this.verify()
  }

  verify() {
    this.viewContainer.clear()
    const verification = this.authService.allowedActions.some((value) => this.permission.map(p => p.toLowerCase()).includes(value));
    if (verification) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      return;
    }
    this.viewContainer.clear();
  }
}
