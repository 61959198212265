import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './../guards/auth.guard';
import { CarTransferComponent } from './register/car-transfer/car-transfer.component';
import { ClientComponent } from './client/client.component';
import { HomeComponent } from './home/home.component';
import { MasterComponent } from './master.component';
import { PartnerComponent } from './partner/partner.component';
import { CarRegisterComponent } from './register/car-register/car-register.component';
import { CarRegisterUsedComponent } from './register/car-register-used/car-register-used.component';
import { CarOwnRegisterUsedComponent } from './register/car-own-register-used/car-own-register-used.component';
import { ClientRegisterComponent } from './register/client-register/client-register.component';
import { UserRegisterComponent } from './register/user-register/user-register.component';
import { UserComponent } from './user/user.component';
import { ZeroCarComponent } from './zero-car/zero-car.component';
import { CarAuthorizeTransferComponent } from './register/car-authorize-transfer/car-authorize-transfer.component';
import { CarAuthorizeTransferUsedComponent } from './register/car-authorize-transfer-used/car-authorize-transfer-used.component';
import { OwnerRegisterComponent } from './register/owner-register/owner-register.component';
import { OwnerComponent } from './owner/owner.component';
import { CarExitStockComponent } from './register/car-exit-stock/car-exit-stock.component';
import { UserChangePasswordComponent } from './user-change-password/user-change-password.component';
import { UserFileComponent } from './user-file/user-file.component';
import { CarComponent } from './car/car.component';
import { PartnerRegisterComponent } from './register/partner-register/partner-register.component';
import { CarExitStockUsedComponent } from './register/car-exit-stock-used/car-exit-stock-used.component';
import { VendorRegisterComponent } from './register/vendor-register/vendor-register.component';
import { VendorComponent } from './vendor/vendor.component'; 
import { RelProdutividadeComponent } from './relatorios/rel-produtividade/rel-produtividade.component';

let routes: Routes = [
  {
    path: '',
    component: MasterComponent,
    canActivate: [AuthGuard],
    children: [
      { path: '', pathMatch: 'full', redirectTo: 'home' },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'client/list',
        component: ClientComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'client/register',
        component: ClientRegisterComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'client/register/:id',
        component: ClientRegisterComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'user/list',
        component: UserComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'user/register',
        component: UserRegisterComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'user/register/:id',
        component: UserRegisterComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'car/zero/list',
        component: ZeroCarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'car/register/:id',
        component: CarRegisterComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'car/authorize-transfer/:id',
        component: CarAuthorizeTransferComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'car/authorize-transfer-used/:id',
        component: CarAuthorizeTransferUsedComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'car/confirm-transfer',
        component: CarTransferComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'owner/register',
        component: OwnerRegisterComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'owner/register/:id',
        component: OwnerRegisterComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'owner/list',
        component: OwnerComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'car/exit-stock/:id',
        component: CarExitStockComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'user/change-password',
        component: UserChangePasswordComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'client/file',
        component: UserFileComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'car/used/list',
        component: CarComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'car/register-used',
        component: CarRegisterUsedComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'car/own-register-used',
        component: CarOwnRegisterUsedComponent,
        canActivate: [AuthGuard],
      },
      { 
        path: 'partner/list',
        component: PartnerComponent,
        canActivate: [AuthGuard],
      },
      {
        path: 'partner/register',
        component: PartnerRegisterComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'car/exit-stock-used/:id',
        component: CarExitStockUsedComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'vendor/register',
        component: VendorRegisterComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'vendor/register/:id',
        component: VendorRegisterComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'vendor/list',
        component: VendorComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'relatorios/produtividade',
        component: RelProdutividadeComponent,
        canActivate: [AuthGuard]
      },
      
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class MasterRoutingModule { }
