<div class="login-container">
  <div class="row d-flex justify-content-center">
    <div class="col-sm-6">
      <form method="post" role="form" id="login" class="login-form">
        <div class="login-header">
          <img src="assets/images/logo.png" alt="" width="80" />
        </div>
        <div class="form-group">
          <label class="control-label" for="username">Nome de usuário</label>
          <input type="text" class="form-control input-dark" [(ngModel)]="forms.main.login" name="username"
            id="username" autocomplete="off" />
        </div>
        <div class="form-group">
          <label class="control-label" for="password">Senha</label>
          <input type="password" class="form-control input-dark" [(ngModel)]="forms.main.password" name="password"
            id="password" autocomplete="off" />
        </div>
        <div class="form-group">
          <loading-button (click)="doLogin()" [btnClass]="'btn btn-primary w-100'" [isLoading]=buttons.main>
            <i class="fa-lock"></i>
            Entrar
          </loading-button>
        </div>
        <div class="login-footer" style="margin-top: 25px !important;">
          <a (click)="openForgotPassword()" style="font-size: 1.5rem; cursor: pointer;">Esqueceu a senha?</a>
        </div>
      </form>
    </div>
  </div>
</div>

<div class="modal" id="modal">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Selecione um cliente</h2>
        <button (click)="closeModal()" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="client">Cliente</label>
              <select class="form-control" [(ngModel)]="selectedClient" name="client" id="client">
                <option selected value="null">Selecione uma opção</option>
                <option *ngFor="let client of clients" name="address-state" id="address-state"
                  [value]="client.codigoCliente">{{client.nome}}</option>
              </select>
            </div>
          </div>
          <div class="col-12 text-right">
            <button (click)="confirmClient()" class="btn btn-primary">
              Confirmar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modal-password">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Esqueceu a senha?</h2>
        <button (click)="closeForgotModal()" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="email">Email</label>
              <input type="text" class="form-control" name="email" id="email" placeholder="Digite aqui"
                [(ngModel)]="forms.forgot.email">
            </div>
          </div>
          <div class="col-12 text-right">
            <loading-button (click)="forgotPassword()" [btnClass]="'btn btn-primary'" [isLoading]=buttons.main>
              Confirmar</loading-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>