<div class="page-title">
  <div class="title-env">
    <h1 class="title" *ngIf="!vendor">Parceiros | Cadastrar Parceiro</h1>
    <h1 class="title" *ngIf="vendor">Parceiros | Editar Parceiro</h1>
  </div>
</div>

<div class="panel panel-default panel-border" *permission="['Proprietario-Cadastrar']">
  <div class="panel-heading">
    Informações do proprietário
  </div>
  <form role="form">
    <div class="panel-body">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="name">Nome completo <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="name" id="name" [(ngModel)]="forms.main.nome" maxlength="=10"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="cpf-cnpj">CNPJ<span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="cnpj" id="cnpj" [(ngModel)]="forms.main.cnpj"
               maxlength="18" placeholder="Digite aqui">
          </div>
        </div>
      </div>
    </div>  
    <div class="d-flex justify-content-end">
      <loading-button (click)="vendor ? edit() : save()" [btnClass]="'btn btn-primary'" [isLoading]="buttons.main">
        Salvar
      </loading-button>
    </div>
  </form>
</div>