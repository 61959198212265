import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CityService } from 'src/app/services/city.service';
import { OwnerService } from 'src/app/services/owner.service';
import { ICity, IOwner } from 'src/app/types';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-owner-register',
  templateUrl: './owner-register.component.html',
  styleUrls: ['./owner-register.component.scss']
})
export class OwnerRegisterComponent implements OnInit {

  public forms = {
    main: {
      codigoCliente: 0,
      codigo: 0,
      nome: '',
      cpfcnpj: '',
      tipoPessoa: null,
      cep: '',
      endereco: '',
      enderecoNum: '',
      enderecoComp: '',
      bairro: '',
      codigoMunicipio: 0,
      email: '',
      telefone: '',
      celular: ''
    }
  }

  public buttons = {
    main: false
  }

  public states = [
    'AC',
    'AL',
    'AM',
    'AP',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MG',
    'MS',
    'MT',
    'PA',
    'PB',
    'PE',
    'PI',
    'PR',
    'RJ',
    'RN',
    'RO',
    'RR',
    'RS',
    'SC',
    'SE',
    'SP',
    'TO'
  ]
  public selectedState: string = null
  public cities: ICity[] = []
  public citiesLoading = false

  public owner: IOwner = null

  constructor(
    private authService: AuthService,
    private ownerService: OwnerService,
    private route: ActivatedRoute,
    private router: Router,
    private cityService: CityService
  ) { }

  ngOnInit(): void {
    this.forms.main.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.route.paramMap.subscribe(paramMap => {
      if (paramMap.get('id')) {
        this.get(parseInt(paramMap.get('id')));
      }
    })
  }

  get(id: number) {
    this.buttons.main = !this.buttons.main
    const codigoCliente = this.forms.main.codigoCliente 
    this.ownerService.get({ codigo: id, codigoCliente, cpf: '' }).subscribe(
      (owner) => {
        this.buttons.main = !this.buttons.main
        this.forms.main = owner
        this.owner = owner
        this.cityService.get({ codigoCliente: codigoCliente, id: this.owner.codigoMunicipio, uf:'' }).subscribe(
          (city) => {
            this.selectedState = city.uf
            this.cityService.list({ codigoCliente: codigoCliente, id: 0, uf:this.selectedState }).subscribe(
              (cities) => {
                this.cities = cities
              },
              (err) => {
                this.buttons.main = !this.buttons.main
                console.log(err)
                Swal.fire({
                  title: 'Ocorreu um erro inesperado!',
                  text: err.error,
                  icon: 'error'
                })
              }
            )
          },
          (err) => {
            this.buttons.main = !this.buttons.main
            console.log(err)
            Swal.fire({
              title: 'Ocorreu um erro inesperado!',
              text: err.error,
              icon: 'error'
            })
          }
        )
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Ocorreu um erro inesperado!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  save() {
    if (this.validateInputs()) {
      this.buttons.main = !this.buttons.main
      this.ownerService.save(this.forms.main).subscribe(
        (res) => {
          console.log(res)
          this.buttons.main = !this.buttons.main
          this.router.navigateByUrl('/owner/list')
          Swal.fire({
            title: 'Sucesso!',
            text: 'Proprietário cadastrado com sucesso',
            icon: 'success'
          })
        },
        (err) => {
          console.log(err)
          this.buttons.main = !this.buttons.main
          Swal.fire({
            title: 'Ocorreu um erro inesperado!',
            text: err.error,
            icon: 'error'
          })
        }
      )
    }
  }

  edit() {
    if (this.validateInputs()) {
      this.buttons.main = !this.buttons.main
      this.ownerService.edit(this.forms.main).subscribe(
        (res) => {
          console.log(res)
          this.buttons.main = !this.buttons.main
          this.router.navigateByUrl('/owner/list')
          Swal.fire({
            title: 'Sucesso!',
            text: res,
            icon: 'success'
          })
        },
        (err) => {
          console.log(err)
          this.buttons.main = !this.buttons.main
          Swal.fire({
            title: 'Ocorreu um erro inesperado!',
            text: err.error,
            icon: 'error'
          })
        }
      )
    }
  }

  getCity() {
    if (this.selectedState) {
      const codigoCliente = this.forms.main.codigoCliente 
      this.forms.main.codigoMunicipio = null
      this.citiesLoading = !this.citiesLoading
      this.cityService.list({codigoCliente: codigoCliente, id: 0, uf:this.selectedState } ).subscribe(
        (cities) => {
          this.cities = cities
          this.citiesLoading = !this.citiesLoading
        },
        (err) => {
          this.citiesLoading = !this.citiesLoading
          console.log(err)
          Swal.fire({
            title: 'Erro!',
            text: err.error,
            icon: 'error'
          })
        }
      )
    }
  }

  validateInputs(): boolean {
    if (!this.forms.main.nome || this.forms.main.nome.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o nome do proprietário',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.cpfcnpj || this.forms.main.cpfcnpj.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o cpf do proprietário',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.tipoPessoa || this.forms.main.tipoPessoa.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o tipo de pessoa do proprietário',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.cep || this.forms.main.cep.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o CEP do proprietário',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.endereco || this.forms.main.endereco.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o endereço do proprietário',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.enderecoNum || this.forms.main.enderecoNum.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o número do endereço do proprietário',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.enderecoComp || this.forms.main.enderecoComp.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o complemento do endereço do proprietário',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.bairro || this.forms.main.bairro.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o bairro do proprietário',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.codigoMunicipio || this.forms.main.codigoMunicipio === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o munícipio do proprietário',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.email || this.forms.main.email.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o email do proprietário',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.telefone || this.forms.main.telefone.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o telefone do proprietário',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.celular || this.forms.main.celular.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o celular do proprietário',
        icon: 'error'
      })
      return false
    }
    return true
  }

}
