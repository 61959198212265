<div class="page-title">
    <div class="title-env">
      <h1 class="title">Grupo</h1>
      <p class="description">This is the group page</p>
    </div>
    <div class="breadcrumb-env">
      <ol class="breadcrumb bc-1">
        <li>
          <span>
            <i class="fa-home"></i>Home
          </span>
        </li>
        <li>
          <span>Forms</span>
        </li>
        <li class="active">
          <strong>Native Elements</strong>
        </li>
      </ol>
    </div>
  </div>