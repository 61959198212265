import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CarService } from 'src/app/services/car.service';
import { OwnerService } from 'src/app/services/owner.service';
import * as moment from 'moment'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-car-own-register-used',
  templateUrl: './car-own-register-used.component.html',
  styleUrls: ['./car-own-register-used.component.scss']
})
export class CarOwnRegisterUsedComponent implements OnInit {

  public forms = {
    main: { 
      codigoCliente: 0,      
      numeroCrv: '',
      tipoCrv:'',      
      codigoSegurancaCrv: '',
      placa: '',
      renavam: '', 
      descricao: '',
      anoFabricacao: new Date().getFullYear(),
      anoModelo: new Date().getFullYear(),  
      dataEntradaEstoque: '',
      dataHoraMedicaoHodometro: '',
      quilometragemHodometro: 0 
    }
  }

  public buttons = {
    main: false
  }

  public dataEntradaEstoque = ''
  public dataHoraMedicaoHodometro = '' 
  public nameLoading = false
  public buyerName = '' 

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private carService: CarService,
    private ownerService: OwnerService,
    private router: Router
  ) { }

  ngOnInit(): void { 
    this.forms.main.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
  }

  save() {
    if (this.validateInputs()) {
      this.buttons.main = !this.buttons.main
      this.forms.main.dataEntradaEstoque = moment(this.dataEntradaEstoque, 'DD/MM/YYYY').toISOString().split('T')[0]
      this.forms.main.dataHoraMedicaoHodometro = moment(this.dataHoraMedicaoHodometro, 'DD/MM/YYYY hh:mm').format('YYYY-MM-DDTHH:mm:ss')
      this.carService.approveEntryStockOwnUsed(this.forms.main).subscribe(
        (res) => {
          console.log(res)
          this.buttons.main = !this.buttons.main
          this.router.navigateByUrl('/car/used/list')
          Swal.fire({
            title: 'Sucesso!',
            text: res,
            icon: 'success'
          })
        },
        (err) => {
          this.buttons.main = !this.buttons.main
          console.log(err)
          Swal.fire({
            title: 'Erro!',
            text: err.error,
            icon: 'error'
          })
        }
      )
    }
  }

  validateInputs(): boolean {
    
    if (!this.forms.main.descricao || this.forms.main.descricao.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha a descrição',
        icon: 'error'
      })
      return false
    } 
    if (!this.forms.main.anoFabricacao || this.forms.main.anoFabricacao === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o ano da fabricação',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.anoModelo || this.forms.main.anoModelo === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o ano do modelo',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.tipoCrv || this.forms.main.tipoCrv.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o tipo CRV',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.numeroCrv || this.forms.main.numeroCrv.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o numero CRV',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.placa || this.forms.main.placa.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha a placa',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.renavam || this.forms.main.renavam.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o renavam',
        icon: 'error'
      })
      return false
    }
    if (!this.dataEntradaEstoque || this.dataEntradaEstoque.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha a data de entrada no estoque',
        icon: 'error'
      })
      return false
    }
    if (!this.dataHoraMedicaoHodometro || this.dataHoraMedicaoHodometro.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha a data e hora da medição do hodômetro',
        icon: 'error'
      })
      return false
    } 
    return true
  } 
   
}
