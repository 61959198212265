<!-- add class "sidebar-collapsed" to close sidebar by default, "chat-visible" to make chat appear always -->
<!-- Add "fixed" class to make the sidebar fixed always to the browser viewport. -->
<!-- Adding class "toggle-others" will keep only one menu item open at a time. -->
<!-- Adding class "collapsed" collapse sidebar root elements and show only icons. -->
<div class="sidebar-menu-inner">
  <header class="logo-env">
    <!-- logo -->
    <div class="logo">
      <a href="dashboard-1.html" class="logo-expanded">
        <img src="assets/images/logo.png" width="80" alt="" />
      </a>
      <a href="dashboard-1.html" class="logo-collapsed">
        <img src="assets/images/logo.png" width="40" alt="" />
      </a>
    </div>
    <!-- This will toggle the mobile menu and will be visible only on mobile devices -->
    <div class="mobile-menu-toggle visible-xs">
      <a href="#" data-toggle="mobile-menu">
        <i class="fa-bars"></i>
      </a>
    </div>
  </header>

  <ul id="main-menu" class="main-menu" *ngIf="!user.selectedClient.pendencia">
    <!-- add class "multiple-expanded" to allow multiple submenus to open -->
    <!-- class "auto-inherit-active-class" will automatically add "active" class for parent elements who are marked already with class "active" -->
    <li [ngClass]="{'active': getActualRoute() === '/home'}">
      <a [routerLink]="['/home']">
        <i class="fas fa-home"></i>
        <span class="title">Home</span>
      </a>
    </li>
    <li [ngClass]="{'active': getActualRoute().startsWith('/client')}" [hidden]="isPermission(['Cliente-Listar','Cliente-Cadastrar','Cliente-Alterar'])">
      <a href="#">
        <i class="fa-solid fa-user"></i>
        <span class="title">Clientes</span>
      </a>
      <ul [ngStyle]="{'display': getActualRoute().startsWith('/client') ? 'block' : ''}">
        <li [ngClass]="{'active': getActualRoute() === '/client/list'}" *permission="['Cliente-Listar']">
          <a [routerLink]="['/client/list']">
            <span class="title">Listar</span>
          </a>
        </li>
        <li [ngClass]="{'active': getActualRoute().startsWith('/client/register')}" *permission="['Cliente-Cadastrar']">
          <a [routerLink]="['/client/register']">
            <span class="title">Cadastrar</span>
          </a>
        </li>
        <li [ngClass]="{'active': getActualRoute().startsWith('/client/file')}" *permission="['Cliente-Alterar']">
          <a [routerLink]="['/client/file']">
            <span class="title">Atualizar certificado</span>
          </a>
        </li>
      </ul>
    </li>
    <li [ngClass]="{'active': getActualRoute().startsWith('/car')}" [hidden]="isPermission(['VeiculoZero-Listar','VeiculoZero-ConfirmarTransferencia'])" >
      <a href="#">
        <i class="fa-solid fa-exchange"></i>
        <span class="title">Veículos zero</span>
      </a>
      <ul [ngStyle]="{'display': getActualRoute().startsWith('/car') ? 'block' : ''}">
        <li [ngClass]="{'active': getActualRoute() === '/car/zero/list'}" *permission="['VeiculoZero-Listar']">
          <a [routerLink]="['/car/zero/list']">
            Movimentações
          </a>
        </li>
        <li [ngClass]="{'active': getActualRoute() === '/car/confirm-transfer'}"
          *permission="['VeiculoZero-ConfirmarTransferencia']">
          <a [routerLink]="['/car/confirm-transfer']">
            Confirmar transferência
          </a>
        </li>
      </ul>
    </li>
    <li [ngClass]="{'active': getActualRoute().startsWith('/car')}" [hidden]="isPermission(['VeiculoUsado-Listar'])" >
      <a href="#">
        <i class="fa-solid fa-exchange"></i>
        <span class="title">Veículos usados</span>
      </a>
      <ul [ngStyle]="{'display': getActualRoute().startsWith('/car') ? 'block' : ''}">
        <li [ngClass]="{'active': getActualRoute() === '/car/used/list'}">
          <a [routerLink]="['/car/used/list']"  *permission="['VeiculoUsado-Listar']" > 
            Movimentações
          </a>
        </li>
         
      </ul>
    </li>
    <li [ngClass]="{'active': getActualRoute().startsWith('/user')}" [hidden]="isPermission(['Usuario-Listar','Usuario-Cadastrar'])" >
      <a href="#">
        <i class="fa-solid fa-users"></i>
        <span class="title">Usuários</span>
      </a>
      <ul [ngStyle]="{'display': getActualRoute().startsWith('/user') ? 'block' : ''}">
        <li [ngClass]="{'active': getActualRoute() === '/user/list'}" *permission="['Usuario-Listar']">
          <a [routerLink]="['/user/list']">Listar</a>
        </li>
        <li [ngClass]="{'active': getActualRoute() === '/user/register'}" *permission="['Usuario-Cadastrar']">
          <a [routerLink]="['/user/register']">Cadastrar</a>
        </li>
      </ul>
    </li>
    <li [ngClass]="{'active': getActualRoute().startsWith('/owner')}" [hidden]="isPermission(['Proprietario-Listar','Proprietario-Cadastrar'])" >
      <a href="#">
        <i class="fa-suitcase"></i>
        <span class="title">Proprietários</span>
      </a>
      <ul [ngStyle]="{'display': getActualRoute().startsWith('/owner') ? 'block' : ''}">
        <li [ngClass]="{'active': getActualRoute() === '/owner/list'}" *permission="['Proprietario-Listar']">
          <a [routerLink]="['/owner/list']">Listar</a>
        </li>
        <li [ngClass]="{'active': getActualRoute() === '/owner/register'}" *permission="['Proprietario-Cadastrar']">
          <a [routerLink]="['/owner/register']">Cadastrar</a>
        </li>
      </ul>
    </li>
    <li [ngClass]="{'active': getActualRoute().startsWith('/partner')}"  [hidden]="isPermission(['Parceiro-Listar','Parceiro-Cadastrar'])">
      <a href="#">
        <i class="fa-solid fa-user"></i>
        <span>Associados</span>
      </a>
      <ul [ngStyle]="{'display': getActualRoute().startsWith('/partner') ? 'block' : ''}">
        <li [ngClass]="{'active': getActualRoute() === '/partner/list'}" *permission="['Parceiro-Listar']">
          <a [routerLink]="['/partner/list']">Listar</a>
        </li>
        <li [ngClass]="{'active': getActualRoute() === '/partner/register'}" *permission="['Parceiro-Cadastrar']">
          <a [routerLink]="['/partner/register']">Cadastrar</a>
        </li>
      </ul>
    </li>
    
    <li [ngClass]="{'active': getActualRoute().startsWith('/vendor')}" [hidden]="isPermission(['Vendedor-Listar','Vendedor-Cadastrar'])" >
      <a href="#">
        <i class="fa-suitcase"></i>
        <span class="title">Parceiros</span>
      </a>
      <ul [ngStyle]="{'display': getActualRoute().startsWith('/vendor') ? 'block' : ''}">
        <li [ngClass]="{'active': getActualRoute() === '/vendor/list'}" *permission="['Vendedor-Listar']">
          <a [routerLink]="['/vendor/list']">Listar</a>
        </li>
        <li [ngClass]="{'active': getActualRoute() === '/vendor/register'}" *permission="['Vendedor-Cadastrar']">
          <a [routerLink]="['/vendor/register']">Cadastrar</a>
        </li>
      </ul>
    </li>
    
    <li [ngClass]="{'active': getActualRoute().startsWith('/relatorios/produtividade')}" [hidden]="isPermission(['Relatorio-Produtividade'])" >
      <a href="#">
        <i class="fa-suitcase"></i>
        <span class="title">Relatórios</span>
      </a>
      <ul [ngStyle]="{'display': getActualRoute().startsWith('/relatorios/produtividade') ? 'block' : ''}">
        <li [ngClass]="{'active': getActualRoute() === '/relatorios/produtividade'}" *permission="['Relatorio-Produtividade']">
          <a [routerLink]="['/relatorios/produtividade']">Produtividade</a>
        </li> 
      </ul>
    </li>
  </ul>
</div>