import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CarService } from 'src/app/services/car.service';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-car-authorize-transfer',
  templateUrl: './car-authorize-transfer.component.html',
  styleUrls: ['./car-authorize-transfer.component.scss']
})
export class CarAuthorizeTransferComponent implements OnInit {

  public forms = {
    main: {
      cnpjEstabelecimentoDestino: '',
      codigoCliente: 0,
      codigoVeiculo: 0,
      valorVenda: null
    }
  }

  public buttons = {
    main: false
  }

  constructor(
    private authService: AuthService,
    private carsService: CarService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.forms.main.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.route.paramMap.subscribe(paramMap => {
      this.forms.main.codigoVeiculo = parseInt(paramMap.get('id'))
    })
  }

  save() {
    if (!this.forms.main.cnpjEstabelecimentoDestino || this.forms.main.cnpjEstabelecimentoDestino.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o cnpj do estabelecimento destinado',
        icon: 'error'
      })
      return
    }
    if (!this.forms.main.valorVenda || this.forms.main.valorVenda.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o valor da nota',
        icon: 'error'
      })
      return
    }
    this.buttons.main = !this.buttons.main
    this.carsService.authorizeTransfer(this.forms.main).subscribe(
      (res) => {
        this.buttons.main = !this.buttons.main
        this.router.navigateByUrl('/car/zero/list')
        Swal.fire({
          title: 'Sucesso!',
          text: res,
          icon: 'success'
        })
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

}
