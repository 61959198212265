<div class="page-title">
  <div class="row">
    <div class="col-6">
      <div class="title-env">
        <h1 class="title">Associados</h1>
      </div>
    </div>
    <div class="col-6 text-right" style="padding-right: 50px;" *permission="['Parceiro-Cadastrar']">
      <button class="btn btn-primary" (click)="register()">Cadastrar associado</button>
    </div>
  </div>
</div>

<div class="panel panel-default panel-border">
  <div class="panel-heading">
    Filtrar associados
  </div>
  <div class="panel-body">
    <form role="form">
      <div class="row">
        <div class="col-sm-6 col-12">
          <div class="form-group">
            <label for="parceiro">Nome</label>
            <input type="text" class="form-control" name="parceiro" [(ngModel)]="forms.filter.descricao" id="parceiro"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="tipo">Tipo <span class="mandatory">*</span></label>
            <select class="form-control" name="tipo" id="tipo" [(ngModel)]="forms.filter.tipo">
              <option value="" selected></option>
              <option value="A">Assoveba</option>
              <option value="S">SinCovd</option>
            </select>
          </div>
        </div>
        <div class="col-12">
          <loading-button (click)="search(1)" class="d-flex justify-content-end" [btnClass]="'btn btn-primary'"
            [isLoading]="buttons.main">
            Filtrar</loading-button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="panel-body" *permission="['Parceiro-Listar']">
  <div class="row" *ngIf="partners.length > 0">
    <div class="col-12">
      <table class="table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>CNPJ</th>
            <th>Tipo</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let partner of partners">
            <td>{{partner.nome}}</td>
            <td>{{partner.cnpj | mask:'00.000.000/0000-00'}}</td>
            <td>{{getPartnerType(partner.tipo)}}</td>
            <td class="d-flex justify-content-center">
              <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                  Ações
                </button>
                <ul class="dropdown-menu" role="menu">
                  <li *permission="['Proprietario-Alterar']">
                    <a class="dropdown-item" (click)="openModalDelete(partner.codigo)">Deletar</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-12" *ngIf="partners.length > 0">
        <app-loading-pagination-button *ngIf="partners.length > 0" [isLoading]="buttons.main"
          (click)="search(forms.filter.page + 1)">
        </app-loading-pagination-button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modal-delete">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Deletar associado?</h2>
        <button (click)="closeModal('modal-delete')" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 text-right">
            <button (click)="delete()" class="btn btn-primary">
              Sim</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>