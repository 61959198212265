import { Component, OnInit } from '@angular/core';
import { element } from 'protractor';
import { AuthService } from 'src/app/services/auth.service';
import { IUser } from 'src/app/types';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public user: IUser = this.authService.getUser()

  constructor(
    private authService: AuthService
  ) { }

  ngOnInit(): void {
  }

  public getActualRoute(): String {
    const url = document.URL.split(window.location.origin)
    if (url.length < 1) {
      return
    }
    return url[1]
  }

  public isPermission(values:string[]): boolean{ 
    for (var i = 0; i < values.length; i++) {
      if (this.authService.allowedActions.indexOf(values[i].toLocaleLowerCase()) > -1) {
        return false;
      }
    }
    return true;
  }
}
