<div class="page-title">
  <div class="row">
    <div class="col-6">
      <div class="title-env">
        <h1 class="title">Meus Clientes</h1>
      </div>
    </div>
    <div class="col-6 text-right" style="padding-right: 50px;" *permission="['Cliente-Cadastrar']">
      <button class="btn btn-primary" (click)="register()">Cadastrar cliente</button>
    </div>
  </div>
</div>

<div class="panel panel-default panel-border">
  <div class="panel-heading">
    Filtrar clientes
  </div>
  <div class="panel-body">
    <form role="form">
      <div class="row">
        <div class="col-sm-6 col-12">
          <div class="form-group">
            <label for="fantasy">Nome fantasia</label>
            <input type="text" class="form-control" name="fantasy" [(ngModel)]="forms.filter.fantasia" id="fantasy"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-sm-6 col-12">
          <div class="form-group">
            <label for="company-name">Razão social</label>
            <input type="text" class="form-control" name="company-name" [(ngModel)]="forms.filter.razao_Social"
              id="company-name" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12">
          <loading-button (click)="search(1)" class="d-flex justify-content-end" [btnClass]="'btn btn-primary'"
            [isLoading]="buttons.main">
            Filtrar</loading-button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="panel-body" *permission="['Cliente-Listar']">
  <div class="row">
    <div class="col-12">
      <table class="table">
        <thead>
          <tr>
            <th>Razão social</th>
            <th>Nome fantasia</th>
            <th>CNPJ</th>
            <th>Celular</th>
            <th>CEP</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let client of clients">
            <td>{{client.razao_social}}</td>
            <td>{{client.fantasia}}</td>
            <td>{{client.cnpj | mask:'00.000.000/0000-00'}}</td>
            <td>{{client.celular | mask: '(00) 0 0000-0000'}}</td>
            <td>{{client.cep | mask: '00000-000'}}</td>
            <td class="d-flex justify-content-center">
              <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                  Ações
                </button>
                <ul class="dropdown-menu" role="menu">
                  <li *permission="['Cliente-Alterar']">
                    <a class="dropdown-item" (click)="edit(client.codigo)">Editar</a>
                  </li>
                  <li *permission="['Usuario-Cadastrar']">
                    <a class="dropdown-item" (click)="newUser(client.codigo)">Cadastrar usuário</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <app-loading-pagination-button *ngIf="clients.length > 0" [isLoading]="buttons.main"
        (click)="search(forms.filter.page + 1)">
      </app-loading-pagination-button>
    </div>
  </div>
</div>