import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CityService } from 'src/app/services/city.service';
import { ClientService } from 'src/app/services/client.service';
import { ICity, ICLient } from 'src/app/types';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-client-register',
  templateUrl: './client-register.component.html',
  styleUrls: ['./client-register.component.scss']
})
export class ClientRegisterComponent implements OnInit {

  public forms = {
    main: {
      codigo: 0,
      razao_social: '',
      ativo: true,
      cnpj: '',
      fantasia: '',
      telefone: '',
      celular: '',
      tipo_Estabelecimento: null,
      cep: '',
      endereco: '',
      endereco_numero: '',
      endereco_complemento: '',
      bairro: '',
      codigoMunicipio: null,
      email: '',
      responsavel: {
        nome: '',
        cpf: '',
        email: ''
      },
      responsavel_financeiro: {
        nome: '',
        cpf: '',
        email: ''
      }
    }
  }

  public buttons = {
    main: false
  }

  public states = [
    'AC',
    'AL',
    'AM',
    'AP',
    'BA',
    'CE',
    'DF',
    'ES',
    'GO',
    'MA',
    'MG',
    'MS',
    'MT',
    'PA',
    'PB',
    'PE',
    'PI',
    'PR',
    'RJ',
    'RN',
    'RO',
    'RR',
    'RS',
    'SC',
    'SE',
    'SP',
    'TO'
  ]
  public selectedState = null
  public cities: ICity[] = []
  public citiesLoading = false
  public codigoCliente = 0

  public client: ICLient 


  constructor(
    private route: ActivatedRoute,
    private clientService: ClientService,
    private cityService: CityService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.route.paramMap.subscribe(paramMap => {
      if (paramMap.get('id')) {
        this.get(paramMap.get('id'));
      }
    })
  }

  get(id: string) {
    this.citiesLoading = !this.citiesLoading
    this.clientService.get(id).subscribe(
      (client) => {
        this.client = client
        this.forms.main = client 
        this.cityService.get({codigoCliente: this.codigoCliente, id: this.client.codigoMunicipio, uf:'' }).subscribe(
          (city) => {
            this.selectedState = city.uf
            this.cityService.list({codigoCliente: client.codigo, id: 0, uf:city.uf }).subscribe(
              (cities) => {
                this.cities = cities
                this.citiesLoading = !this.citiesLoading
              },
              (err) => {
                console.log(err);
                Swal.fire({
                  title: 'Erro!',
                  text: err.error,
                  icon: 'error'
                })
              }
            )
          },
          (err) => {
            console.log(err);
            Swal.fire({
              title: 'Erro!',
              text: err.error,
              icon: 'error'
            })
          }
        )
      },
      (err) => {
        console.log(err);
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  save() {
    if (this.validateInputs()) {
      this.buttons.main = !this.buttons.main
      this.clientService.save(this.forms.main).subscribe(
        (res) => {
          this.buttons.main = !this.buttons.main
          console.log(res)
          Swal.fire({
            title: 'Sucesso!',
            text: res,
            icon: 'success'
          })
        },
        (err) => {
          this.buttons.main = !this.buttons.main
          console.log(err)
          Swal.fire({
            title: 'Erro!',
            text: err.error,
            icon: 'error'
          })
        }
      )
    }
  }

  update() {
    if (this.validateInputs()) {
      this.buttons.main = !this.buttons.main
      this.clientService.edit(this.forms.main).subscribe(
        (res) => {
          this.buttons.main = !this.buttons.main
          console.log(res)
        },
        (err) => {
          this.buttons.main = !this.buttons.main
          console.log(err)
          Swal.fire({
            title: 'Erro!',
            text: err.error,
            icon: 'error'
          })
        }
      )
    }
  }

  getCity() {
    if (this.selectedState) {
      this.forms.main.codigoMunicipio = null
      this.citiesLoading = !this.citiesLoading 
      this.cityService.list({codigoCliente: this.codigoCliente, id: 0, uf:this.selectedState}).subscribe(
        (cities) => {
          this.cities = cities
          this.citiesLoading = !this.citiesLoading
        },
        (err) => {
          this.citiesLoading = !this.citiesLoading
          console.log(err)
          Swal.fire({
            title: 'Erro!',
            text: err.error,
            icon: 'error'
          })
        }
      )
    }
  }

  validateInputs(): boolean {
    if (!this.forms.main.fantasia || this.forms.main.fantasia.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o nome fantasia!',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.razao_social || this.forms.main.razao_social.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha a razão social!',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.cnpj || this.forms.main.cnpj.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o CNPJ!',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.tipo_Estabelecimento) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o tipo de estabelecimento!',
        icon: 'error'
      })
      return false
    }
    if (!this.selectedState || this.selectedState.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o estado!',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.endereco || this.forms.main.endereco.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o logradouro!',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.cep || this.forms.main.cep.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o CEP!',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.endereco_numero || this.forms.main.endereco_numero.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o número!',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.email || this.forms.main.email.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o e-mail!',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.bairro || this.forms.main.bairro.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o bairro!',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.responsavel.nome || this.forms.main.responsavel.nome.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o nome do responsável!',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.responsavel.cpf || this.forms.main.responsavel.cpf.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o CPF do responsável!',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.responsavel.email || this.forms.main.responsavel.email.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o email do responsável!',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.responsavel_financeiro.nome || this.forms.main.responsavel_financeiro.nome.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o nome do responsável financeiro!',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.responsavel_financeiro.email || this.forms.main.responsavel_financeiro.email.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o email do responsável financeiro!',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.responsavel_financeiro.cpf || this.forms.main.responsavel_financeiro.cpf.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o CPF do responsável financeiro!',
        icon: 'error'
      })
      return false
    }
    return true
  }

}
