import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { ICity, IResponse, ICityFilter } from '../types';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class CityService {

  constructor(
    private baseService: BaseService,
    private http: HttpClient,
  ) { }

  list(data: ICityFilter): Observable<ICity[]> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Municipio/Lista`, data, options)
      .pipe(
        map((res: ICity[]) => res),
        finalize(() => 'Completed!')
      ) 
  }

  get(data: ICityFilter): Observable<ICity> {    
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Municipio/Details`, data, options)
      .pipe(
        map((res: ICity) => res),
        finalize(() => 'Completed!')
      )  
  }
}
