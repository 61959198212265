import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { CarService } from 'src/app/services/car.service';
import * as moment from 'moment'
import Swal from 'sweetalert2'

@Component({
  selector: 'app-car-register',
  templateUrl: './car-register.component.html',
  styleUrls: ['./car-register.component.scss']
})
export class CarRegisterComponent implements OnInit {

  public forms = {
    main: {
      codigoVeiculo: 0,
      codigoCliente: 0,
      descricao: '',
      anoFabricacao: new Date().getFullYear(),
      anoModelo: new Date().getFullYear(),
      chaveNotaFiscal: '',
      dataEntradaEstoque: '',
      dataHoraMedicaoHodometro: '',
      quilometragemHodometro: 0,
      valorCompra: null
    }
  }

  public buttons = {
    main: false
  }

  public dataEntradaEstoque = ''
  public dataHoraMedicaoHodometro = ''

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private carService: CarService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      this.forms.main.codigoVeiculo = parseInt(paramMap.get('id'))
    })
    this.forms.main.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
  }

  save() {
    if (this.validateInputs()) {
      this.buttons.main = !this.buttons.main
      this.forms.main.dataEntradaEstoque = moment(this.dataEntradaEstoque, 'DD/MM/YYYY').toISOString().split('T')[0]
      this.forms.main.dataHoraMedicaoHodometro = moment(this.dataHoraMedicaoHodometro, 'DD/MM/YYYY hh:mm').format('YYYY-MM-DDTHH:mm:ss')
      this.carService.approveEntryStock(this.forms.main).subscribe(
        (res) => {
          console.log(res)
          this.buttons.main = !this.buttons.main
          this.router.navigateByUrl('/car/zero/list')
          Swal.fire({
            title: 'Sucesso!',
            text: res,
            icon: 'success'
          })
        },
        (err) => {
          this.buttons.main = !this.buttons.main
          console.log(err)
          Swal.fire({
            title: 'Erro!',
            text: err.error,
            icon: 'error'
          })
        }
      )
    }
  }

  validateInputs(): boolean {
    if (!this.forms.main.descricao || this.forms.main.descricao.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha a descrição',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.chaveNotaFiscal || this.forms.main.chaveNotaFiscal.length !== 44) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha a chave da nota fiscal com exatamente 44 caracteres',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.anoFabricacao || this.forms.main.anoFabricacao === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o ano da fabricação',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.anoModelo || this.forms.main.anoModelo === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o ano do modelo',
        icon: 'error'
      })
      return false
    }
    if (!this.dataEntradaEstoque || this.dataEntradaEstoque.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha a data de entrada no estoque',
        icon: 'error'
      })
      return false
    }
    if (!this.dataHoraMedicaoHodometro || this.dataHoraMedicaoHodometro.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha a data e hora da medição do hodômetro',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.valorCompra || this.forms.main.valorCompra === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o valor da nota',
        icon: 'error'
      })
      return false
    }
    return true
  }

}
