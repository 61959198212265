import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AuthService } from '../services/auth.service';
import { IClientLoginResponse, ILoginResponse } from '../types';

declare const $;
declare const setup_xenon_login;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

  public forms = {
    main: {
      login: '',
      password: ''
    },
    forgot: {
      email: ''
    }
  }

  public buttons = {
    main: false
  }

  public selectedClient: number = null
  public clients: IClientLoginResponse[] = []

  private loginResponse: ILoginResponse
  private httpClient: HttpClient;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['/home']);
      return;
    } else {
      let tokenUser = "";
      this.route.queryParams 
      .subscribe(params => { 
        tokenUser = params.token; 
      });
      
      document.querySelector('body').className = 'page-body login-page';
      if (( tokenUser != undefined) && ( tokenUser !== ""))
      {
        this.doLoginToken(tokenUser);
      }
    }
  }
  ngAfterViewInit(): void {
    setup_xenon_login($);
  }

  doLogin() {
    if (this.validateInputs()) {
      this.buttons.main = !this.buttons.main
      this.authService.login({
        login: this.forms.main.login,
        password: this.forms.main.password
      }).subscribe(
        (res) => {
          this.loginResponse = res
          this.buttons.main = !this.buttons.main
          if (this.loginResponse.clientes.length === 1) {
            this.authService.setUser({
              ...this.loginResponse.user,
              clients: this.loginResponse.clientes,
              token: this.loginResponse.token,
              selectedClient: this.loginResponse.clientes[0]
            })
            this.authService.getPermissions(this.loginResponse.clientes[0].codigoCliente)
            if (this.authService.getUser().selectedClient.pendencia) {
              this.router.navigate(['/client/file']);
              return
            }
            this.router.navigate(['/home']);
            return
          } else if (this.loginResponse.clientes.length > 1) {
            this.clients = res.clientes
            this.openModal()
            return
          }
          this.authService.setUser({
            ...res.user,
            clients: res.clientes,
            token: res.token,
            selectedClient: null
          })
          this.router.navigate(['/home']);
        },
        (err) => {
          this.buttons.main = !this.buttons.main
          console.log(err)
          Swal.fire({
            title: 'Erro!',
            text: err.error,
            icon: 'error'
          })
        }
      )
    }
  }

  doLoginToken(_token: string) {
 
      this.buttons.main = !this.buttons.main
      this.authService.loginToken({
        token: _token
      }).subscribe(
        (res) => {
          this.loginResponse = res
          this.buttons.main = !this.buttons.main
          if (this.loginResponse.clientes.length === 1) {
            this.authService.setUser({
              ...this.loginResponse.user,
              clients: this.loginResponse.clientes,
              token: this.loginResponse.token,
              selectedClient: this.loginResponse.clientes[0]
            })
            this.authService.getPermissions(this.loginResponse.clientes[0].codigoCliente)
            if (this.authService.getUser().selectedClient.pendencia) {
              this.router.navigate(['/client/file']);
              return
            }
            this.router.navigate(['/home']);
            return
          } else if (this.loginResponse.clientes.length > 1) {
            this.clients = res.clientes
            this.openModal()
            return
          }
          this.authService.setUser({
            ...res.user,
            clients: res.clientes,
            token: res.token,
            selectedClient: null
          })
          this.router.navigate(['/home']);
        },
        (err) => {
          this.buttons.main = !this.buttons.main
          console.log(err)
          Swal.fire({
            title: 'Erro!',
            text: err.error,
            icon: 'error'
          })
        }
      ) 
  }

  validateInputs() {
    if (!this.forms.main.login) {
      alert('Preencha o login!')
      return false
    }
    if (!this.forms.main.password) {
      alert('Preencha a senha')
      return false
    }
    return true
  }

  confirmClient() {
    if (!this.selectedClient) {
      alert('Selecione um cliente antes de efetuar login')
      return
    }
    const selectedClient = this.clients.find(client => client.codigoCliente == this.selectedClient)
    this.authService.setUser({
      ...this.loginResponse.user,
      clients: this.loginResponse.clientes,
      token: this.loginResponse.token,
      selectedClient
    })
    this.authService.getPermissions(selectedClient.codigoCliente)
    if (this.authService.getUser().selectedClient.pendencia) {
      this.router.navigate(['/client/file']);
      return
    }
    this.router.navigate(['/home']);
    this.closeModal()
  }

  forgotPassword() {
    if (!this.forms.forgot.email || this.forms.forgot.email.length === 0) {
      Swal.fire({
        title: 'Erro',
        text: 'Preencha o email para redefinir a senha',
        icon: 'error'
      })
      return
    }
    this.buttons.main = !this.buttons.main
    this.authService.forgotPassword(this.forms.forgot).subscribe(
      (res) => {
        this.buttons.main = !this.buttons.main
        this.forms.forgot.email = ''
        console.log(res)
        Swal.fire({
          title: 'Sucesso',
          text: res,
          icon: 'success'
        })
        this.closeForgotModal()
      },
      (err) => {
        this.forms.forgot.email = ''
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro',
          text: err.error,
          icon: 'error'
        })
        this.closeForgotModal()
      }
    )
  }

  openModal() {
    $('#modal').modal('show', { backdrop: 'fade' });
  }

  closeModal() {
    $('#modal').modal('hide')
  }

  openForgotPassword() {
    $('#modal-password').modal('show', { backdrop: 'fade' });
  }

  closeForgotModal() {
    $('#modal-password').modal('hide')
  }
}
