import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
declare const $;
declare const setup_xenon;

@Component({
  selector: 'app-master',
  templateUrl: './master.component.html',
  styleUrls: ['./master.component.scss']
})
export class MasterComponent implements OnInit, AfterViewInit {

  constructor(
    private router: Router
  ) { }

  ngOnInit(): void {
    document.querySelector('body').className = 'page-body';
    if (window.location.href.split(window.location.origin)[1] === '/') { // Lógica para redirecionar da URL vazia para a home
      this.router.navigate(['/home']);
    }
  }

  ngAfterViewInit(): void {
    setup_xenon($, window);
  }

}
