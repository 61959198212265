<div class="page-title">
  <div class="title-env">
    <h1 class="title" *ngIf="!user">Meus Usuários | Cadastrar Usuários</h1>
    <h1 class="title" *ngIf="user">Meus Usuários | Editar Usuários</h1>
  </div>
</div>

<div class="panel panel-default panel-border" *permission="['Usuario-Cadastrar']">
  <div class="panel-heading">
    Informações do usuário
  </div>
  <form role="form">
    <div class="panel-body">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="name">Nome <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="name" [(ngModel)]="forms.main.nome" id="name"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="cpf">CPF <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="cpf" [(ngModel)]="forms.main.cpf" mask="000.000.000-00"
              id="cpf" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="email">Email <span class="mandatory">*</span></label>
            <input type="email" class="form-control" name="email" [(ngModel)]="forms.main.email" id="email"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-3">
          <div class="form-group">
            <label for="group">Grupo <span class="mandatory">*</span></label>
            <select class="form-control" [(ngModel)]="forms.main.codigoGrupo" name="group"
              id="group">
              <option selected value="">Selecione uma opção</option>
              <option [hidden]="!isAdmin" name="group-state" id="group-state" value="1">Master</option>
              <option [hidden]="isAdmin" name="group-state" id="group-state" value="2">Master</option>
              <option [hidden]="isAdmin" name="group-state" id="group-state" value="3">Financeiro</option>
              <option [hidden]="isAdmin" name="group-state" id="group-state" value="4">Operador</option>
              <option [hidden]="!isAdmin" name="group-state" id="group-state" value="5">Operador</option>
              <option [hidden]="!isAdmin" name="group-state" id="group-state" value="6">Parceiro</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-3">
          <div class="form-group">
            <label for="status">Status <span class="mandatory">*</span></label>
            <select class="form-control" name="status" id="status" [(ngModel)]="forms.main.ativo">
              <option value="true">Ativo</option>
              <option value="false">Inativo</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-6" *ngIf="!forms.main.codigo">
          <div class="form-group">
            <label for="password">Senha <span class="mandatory">*</span></label>
            <div class="input-group">
              <input [type]="showPassword ? 'text' : 'password'" class="form-control" name="password"
                [(ngModel)]="forms.main.senha" id="password" placeholder="Digite aqui">
              <div class="input-group-append">
                <span class="input-group-text" style="height: 100%;" (click)="toggleFieldTextType()">
                  <i class="fa" [ngClass]="{
                    'fa-eye-slash': !showPassword,
                    'fa-eye': showPassword
                  }"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <loading-button (click)="forms.main.codigo ? update() : save()" [btnClass]="'btn btn-primary'"
        [isLoading]="buttons.main">
        Salvar
      </loading-button>
    </div>
  </form>
</div>
<div class="panel panel-default panel-border" *ngIf="forms.main.codigo">
  <div class="panel-heading">
    Credenciais
  </div>
  <form role="form" *permission="['Usuario-Cadastrar']">
    <div class="panel-body">
      <div class="row">
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="actualPassword">Senha atual</label>
            <div class="input-group">
              <input [type]="showPassword ? 'text' : 'password'" class="form-control" name="actualPassword"
                [(ngModel)]="forms.password.senhaAtual" id="actualPassword" placeholder="Digite aqui">
              <div class="input-group-append">
                <span class="input-group-text" style="height: 100%;" (click)="toggleFieldTextType()">
                  <i class="fa" [ngClass]="{
                    'fa-eye-slash': !showPassword,
                    'fa-eye': showPassword
                  }"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="password">Nova senha</label>
            <div class="input-group">
              <input [type]="showPassword ? 'text' : 'password'" class="form-control" name="password"
                [(ngModel)]="forms.password.senhaNova" id="password" placeholder="Digite aqui">
              <div class="input-group-append">
                <span class="input-group-text" style="height: 100%;" (click)="toggleFieldTextType()">
                  <i class="fa" [ngClass]="{
                    'fa-eye-slash': !showPassword,
                    'fa-eye': showPassword
                  }"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="confirmPassword">Confirmar senha</label>
            <div class="input-group">
              <input [type]="showPassword ? 'text' : 'password'" class="form-control" name="confirmPassword"
                [(ngModel)]="forms.password.confirmPassword" id="confirmPassword" placeholder="Digite aqui">
              <div class="input-group-append">
                <span class="input-group-text" style="height: 100%;" (click)="toggleFieldTextType()">
                  <i class="fa" [ngClass]="{
                    'fa-eye-slash': !showPassword,
                    'fa-eye': showPassword
                  }"></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <loading-button (click)="updatePassword()" class="d-flex justify-content-end" [btnClass]="'btn btn-primary'"
        [isLoading]="buttons.password">
        Atualizar senha
      </loading-button>
    </div>
  </form>
</div>