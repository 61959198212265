<div class="page-title">
  <div class="title-env">
    <h1 class="title" *ngIf="!client">Meus Clientes | Cadastrar Cliente</h1>
    <h1 class="title" *ngIf="client">Meus Clientes | Editar Cliente</h1>
  </div>
</div>

<div class="panel panel-default panel-border" *permission="['Cliente-Cadastrar']">
  <div class="panel-heading">
    Informações do cliente
  </div>
  <form role="form">
    <div class="panel-body">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="fantasy">Nome fantasia <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="fantasy" [(ngModel)]="forms.main.fantasia" id="fantasy"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="company-name">Razão social <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="company-name" [(ngModel)]="forms.main.razao_social"
              id="company-name" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="cnpj">CNPJ <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="cnpj" [(ngModel)]="forms.main.cnpj" id="cnpj"
              mask="00.000.000/0000-00" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="establishment-type">Tipo de estabelecimento <span class="mandatory">*</span></label>
            <select class="form-control" [(ngModel)]="forms.main.tipo_Estabelecimento" name="establishment-type"
              id="establishment-type">
              <option selected value="null">Selecione uma opção</option>
              <option name="address-state" id="address-state" value="C">Concessionária</option>
              <option name="address-state" id="address-state" value="L">Loja</option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-3">
          <div class="form-group">
            <label for="tel">Telefone</label>
            <input type="text" class="form-control" name="tel" [(ngModel)]="forms.main.telefone" id="tel"
              mask="(00) 0000-0000" placeholder=" Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-3">
          <div class="form-group">
            <label for="cel">Celular</label>
            <input type="text" class="form-control" name="cel" [(ngModel)]="forms.main.celular" id="cel"
              mask="(00) 0 0000-0000" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="email">Email <span class="mandatory">*</span></label>
            <input type="email" class="form-control" name="email" [(ngModel)]="forms.main.email"
              id="email" placeholder="Digite aqui">
          </div>
        </div>
      </div>
    </div>
    <div class="panel-heading" style="padding-top: 15px;">
      Endereço
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="address-state">Estado <span class="mandatory">*</span></label>
            <select class="form-control" [(ngModel)]="selectedState" (change)="getCity()" name="address-state"
              id="address-state">
              <option selected value="null">Selecione um estado</option>
              <option *ngFor="let state of states" name="address-state" id="address-state" [value]=state>
                {{state}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="address-city">Munícipio <span class="mandatory">*</span></label>
            <span *ngIf="citiesLoading" class="spinner-border spinner-border-sm" style="margin-left: 10px;"
              role="status" aria-hidden="true"></span>
            <select class="form-control" [disabled]="citiesLoading" [(ngModel)]="forms.main.codigoMunicipio"
              name="address-city" id="address-city">
              <option selected value="null">Selecione um município</option>
              <option *ngFor="let city of cities" name="address-city" id="address-city" [value]=city.id>
                {{city.nome}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="address-street">Logradouro <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="address-street" [(ngModel)]="forms.main.endereco"
              id="address-street" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="address-zipcode">CEP <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="address-zipcode" [(ngModel)]="forms.main.cep"
              id="address-zipcode" mask="00000-000" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="address-number">Número <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="address-number" [(ngModel)]="forms.main.endereco_numero"
              id="address-number" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="address-complement">Complemento</label>
            <input type="text" class="form-control" name="address-complement"
              [(ngModel)]="forms.main.endereco_complemento" id="address-complement" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="address-neighborhood">Bairro <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="address-neighborhood" [(ngModel)]="forms.main.bairro"
              id="address-neighborhood" placeholder="Digite aqui">
          </div>
        </div>
      </div>
    </div>
    <div class="panel-heading" style="padding-top: 15px;" *ngIf="!client">
      Responsável
    </div>
    <div class="panel-body" *ngIf="!client">
      <div class="row">
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="responsible-name">Nome <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="responsible-name" [(ngModel)]="forms.main.responsavel.nome"
              id="responsible-name" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="responsible-cpf">CPF <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="responsible-cpf" [(ngModel)]="forms.main.responsavel.cpf"
              id="responsible-cpf" mask="000.000.000-00" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="responsible-email">Email <span class="mandatory">*</span></label>
            <input type="email" class="form-control" name="responsible-email" [(ngModel)]="forms.main.responsavel.email"
              id="responsible-email" placeholder="Digite aqui">
          </div>
        </div>
      </div>
    </div>
    <div class="panel-heading" style="padding-top: 15px;" *ngIf="!client">
      Responsável Financeiro
    </div>
    <div class="panel-body" *ngIf="!client">
      <div class="row">
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="financial-responsible-name">Nome <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="financial-responsible-name"
              [(ngModel)]="forms.main.responsavel_financeiro.nome" id="financial-responsible-name"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="financial-responsible-cpf">CPF <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="financial-responsible-cpf"
              [(ngModel)]="forms.main.responsavel_financeiro.cpf" id="financial-responsible-cpf" mask="000.000.000-00"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="financial-responsible-email">Email <span class="mandatory">*</span></label>
            <input type="email" class="form-control" name="financial-responsible-email"
              [(ngModel)]="forms.main.responsavel_financeiro.email" id="financial-responsible-email"
              placeholder="Digite aqui">
          </div>
        </div>
      </div>
    </div>
    <loading-button (click)="forms.main.codigo ? update() : save()" class="d-flex justify-content-end"
      [btnClass]="'btn btn-primary'" [isLoading]="buttons.main">
      Salvar
    </loading-button>
  </form>
</div>