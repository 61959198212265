<div class="page-title">
  <div class="row">
    <div class="col-6">
      <div class="title-env">
        <h1 class="title">Consulta de veículos usados</h1>
      </div>
    </div>
  </div>
</div>

<div class="panel panel-default panel-border">
  <div class="panel-heading">
    Filtrar veículos
  </div>
  <div class="panel-body">
    <form role="form">
      <div class="row">
        <div class="col-sm-4 col-12">
          <div class="form-group">
            <label for="chassi">Chassi</label>
            <input type="text" class="form-control" name="chassi" [(ngModel)]="forms.filter.chassi" id="chassi"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-sm-4 col-12">
          <div class="form-group">
            <label for="plate">Placa</label>
            <input type="text" class="form-control" maxlength="7" name="plate" [(ngModel)]="forms.filter.placa" id="plate"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-sm-4 col-12">
          <div class="form-group">
            <label for="renavam">Renavam</label>
            <input type="text" class="form-control" name="renavam" [(ngModel)]="forms.filter.renavam" id="renavam"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-sm-4 col-12">
          <div class="form-group">
            <label for="status">Status</label>
            <select class="form-control" [(ngModel)]="forms.filter.status" name="status" id="status">
              <option value="null">Selecione um status</option>
              <option *ngFor="let status of statuses" name="status" id="status" [value]=status.value>
                {{status.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-4 col-12">
          <div class="form-group">
            <label for="period-start">Período inicial</label>
            <input type="text" class="form-control" name="period-start" [(ngModel)]="dataInicio" id="period-start"
              placeholder="Digite aqui" mask="00/00/0000">
          </div>
        </div>
        <div class="col-sm-4 col-12">
          <div class="form-group">
            <label for="period-end">Período final</label>
            <input type="text" class="form-control" name="period-end" [(ngModel)]="dataFim" id="period-end"
              placeholder="Digite aqui" mask="00/00/0000">
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex justify-content-end">
            <loading-button [btnClass]="'btn btn-primary'" (click)="openCheckStockModal()" style="margin-right: 18px;"
              [isLoading]="buttons.main">
              Atualizar estoque
            </loading-button> 
            
            <div class="btn-group">
                <loading-button type="button" [btnClass]="'btn btn-primary dropdown-toggle'" style="margin-right: 18px;" [isLoading]="buttons.main"
                data-toggle="dropdown">
                Entrada Estoque
              </loading-button>
              <ul class="dropdown-menu" role="menu">
                <li>
                  <a class="dropdown-item" (click)="openAvailabilityStockModal()" *permission="['VeiculoUsado-Aptidao']" >Aptidão Veiculo</a>
                </li>
                <li>
                  <a class="dropdown-item" (click)="registerCar()" *permission="['VeiculoUsado-EntradaEstoque']" >Entrada Estoque</a>
                </li>
                <li>
                  <a class="dropdown-item" (click)="registerOwnCar()" *permission="['VeiculoUsado-EntradaEstoqueProprio']" >Entrada Estoque Próprio</a>
                </li>
              </ul>
            </div>
            <loading-button (click)="search(1)" [btnClass]="'btn btn-primary'" [isLoading]="buttons.main">
              Filtrar</loading-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="panel-body" *permission="['VeiculoUsado-Listar']">
  <div class="row">
    <div class="col-12">
      <table class="table">
        <thead>
          <tr>
            <th>Chassi</th>
            <th>Veículo</th>
            <th>Placa</th>
            <th>Data</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let vehicle of vehicles">
            <td>{{vehicle.chassi}}</td>
            <td>{{vehicle.veiculo}}</td>
            <td>{{vehicle.placa}}</td>
            <td>{{getVehicleDate(vehicle.dataCadastro)}}</td>
            <td>{{vehicle.status}}</td>
            <td class="d-flex justify-content-center" *ngIf="vehicle.proximoStatus.length > 0">
              <div class="btn-group">
                <loading-button type="button" [btnClass]="'btn btn-primary dropdown-toggle'" [isLoading]="buttons.main"
                  data-toggle="dropdown">
                  Ações
                </loading-button>
                <ul class="dropdown-menu" role="menu">                   
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'ConsultaPdfATPV')"> 
                    <a class="dropdown-item" (click)="consultaPdfATPV(vehicle.codigo)" *permission="['VeiculoUsado-ATPV']" >ATPV PDF</a>
                  </li>                
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'CRLVEVeiculo')"> 
                    <a class="dropdown-item" (click)="consultaPdfCRLVE(vehicle.codigo)" *permission="['VeiculoUsado-CRLVE']" >CRLVE</a>
                  </li>        
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'CRLVEVeiculoSeguranca')"> 
                    <a class="dropdown-item" (click)="consultaPdfCRLVESeguranca(vehicle.codigo)" *permission="['VeiculoUsado-CRLVE-Seguranca']" >CRLVE / Código de Segurança</a>
                  </li>
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'EnviarATPV')"> 
                    <a class="dropdown-item" (click)="openSendATPVModal(vehicle.codigo)" *permission="['VeiculoUsado-EnviarATPV']" >Enviar ATPV</a>
                  </li>                 
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'ConsultaATPVAssinatura')"> 
                    <a class="dropdown-item" (click)="consultaATPVAssinatura(vehicle.codigo)" *permission="['VeiculoUsado-ATPVAssinatura']" >ATPV Assinatura</a>
                  </li>                
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'EnviarNF')"> 
                    <a class="dropdown-item" (click)="openSendNFModal(vehicle.codigo)" *permission="['VeiculoUsado-EnviarNFEntrada']" >Enviar NF</a>
                  </li>             
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'EnviarNFSaida')"> 
                    <a class="dropdown-item" (click)="openSendExitNFModal(vehicle.codigo)" *permission="['VeiculoUsado-EnviarNFSaida']" >Enviar NF</a>
                  </li>
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'CancelaEntradaEstoque')"> 
                    <a class="dropdown-item" (click)="openModalCancelEntryStock(vehicle.codigo)" *permission="['VeiculoUsado-CancelarEntrada']"  >Cancela Entrada</a>
                  </li>
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'TermoEntrada')"> 
                    <a class="dropdown-item" (click)="termEntry(vehicle.codigo)" *permission="['VeiculoUsado-TermoEntrada']" >Termo de Entrada</a>
                  </li>
                  
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'AutorizacaoTransferencia')">
                    <a class="dropdown-item" [routerLink]="['/car/authorize-transfer-used/' + vehicle.codigo]"
                      routerLinkActive="router-link-active"
                      *permission="['VeiculoUsado-AutorizarTransferencia']">Autorizar transferência</a>
                  </li>
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'SaidaEstoque')">
                    <a class="dropdown-item" [routerLink]="['/car/exit-stock-used/' + vehicle.codigo]"
                      *permission="['VeiculoUsado-SaidaEstoque']">Saída estoque</a>
                  </li> 
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'TermoSaida')"> 
                    <a class="dropdown-item" (click)="termExit(vehicle.codigo)" *permission="['VeiculoUsado-TermoSaida']" >Termo de Saída</a>
                  </li>
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'CancelaSaidaEstoque')">
                    <a class="dropdown-item" (click)="openModalCancelExitStock(vehicle.codigo)"
                      *permission="['VeiculoUsado-CancelarSaida']">Cancelamento saída
                      estoque</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-12" *ngIf="vehicles.length > 0">
        <app-loading-pagination-button [isLoading]="buttons.main" (click)="search(forms.filter.page + 1)">
        </app-loading-pagination-button>
      </div>
    </div>
  </div>
</div>
  

<div class="modal" id="modal-cancel-entry-stock">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Cancelar entrada de estoque</h2>
        <button (click)="closeModal('modal-cancel-entry-stock')" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <h3 class="mb-4">Deseja realmente cancelar a entrada de estoque deste veículo?</h3>
        <button class="btn btn-primary" (click)="cancelEntryStock()">Confirmar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modal-cancel-exit-stock">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Cancelar saída de estoque</h2>
        <button (click)="closeModal('modal-cancel-exit-stock')" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <h3 class="mb-4">Deseja realmente cancelar a saída de estoque deste veículo?</h3>
        <button class="btn btn-primary" (click)="cancelExitStock()">Confirmar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modal-cancel-auth-transfer">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Cancelar autorização de transferência</h2>
        <button (click)="closeModal('modal-cancel-auth-transfer')" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <h3 class="mb-4">Deseja realmente cancelar a autorização de transferência deste veículo?</h3>
        <button class="btn btn-primary" (click)="cancelAuthTransfer()">Confirmar</button>
      </div>
    </div>
  </div>
</div>
 

<div class="modal" id="modal-check-stock">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Atualizar estoque</h2>
        <button (click)="closeModal('modal-check-stock')" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="chassi">Chassi</label>
              <input [(ngModel)]="forms.check.chassi" class="form-control" type="text" name="chassi" id="chassi">
            </div>
          </div>
          <div class="col-12 text-right">
            <button (click)="checkStock()" class="btn btn-primary">
              Consultar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal" id="modal-display-availability">
  <div class="modal-dialog modal-lg">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Resultado consulta aptidão</h2>
        <button (click)="closeModal('modal-display-availability')" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <div class="row">  
          <div class="col-6">
          <table class="table  table-striped table-sm"> 
            <tbody>
              <tr *ngFor="let vehiclesAv of vehiclesAvailable.slice(0, 9);">
                <td class="td-sm" scope="row" >{{vehiclesAv.descricao}}</td> 
                <td class="td-sm" ><i [className]="vehiclesAv.resultado == 'Sim' ? 'fa fa-check-circle' :'' " ></i></td>  
              </tr>
            </tbody>
          </table>
          </div>
          
          <div class="col-6">
          <table class="table  table-striped table-sm"> 
            <tbody>
              <tr *ngFor="let vehiclesAv of vehiclesAvailable.slice(9, 16);">
                <td class="td-sm" scope="row">{{vehiclesAv.descricao}}</td> 
                <td class="td-sm" ><i [className]="vehiclesAv.resultado == 'Sim' ? 'fa fa-check-circle' : ''" ></i></td>  
              </tr>
            </tbody>
          </table>
        </div>
        <div class="col-12">
          <table class="table  table-striped table-sm"> 
            <tbody>
              <tr *ngFor="let vehiclesAv of vehiclesAvailable.slice(16, 30);">
                <td class="td-sm" scope="row">{{vehiclesAv.descricao}}</td> 
                <td class="td-sm" ><i [className]="vehiclesAv.resultado == 'Sim' ? 'fa fa-check-circle' : ''" ></i>{{vehiclesAv.resultado !== 'Sim' && vehiclesAv.resultado !== 'Não' ? vehiclesAv.resultado :''}}</td>  
              </tr>
            </tbody>
          </table>
        </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal" id="modal-check-availability">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Aptidão veiculo</h2>
        <button (click)="closeModal('modal-check-availability')" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="tipoCrv">Tipo CRV *</label>             
              <select class="form-control" [(ngModel)]="forms.availability.tipoCrv" name="tipoCrv" id="tipoCrv">
                <option selected value="null">Selecione uma opção</option>
                <option value="AZUL">AZUL</option>
                <option value="BRANCO">BRANCO</option>
                <option value="DIGITAL">DIGITAL</option>
                <option value="VERDE">VERDE</option>
              </select> 
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="numeroCrv">Numero CRV *</label>
              <input type="text" class="form-control" name="numeroCrv" [(ngModel)]="forms.availability.numeroCrv" maxlength="12" minlength="12"
                id="numeroCrv" placeholder="Digite aqui">
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="placa">Placa *</label>
              <input type="text" class="form-control" name="placa" [(ngModel)]="forms.availability.placa" maxlength="7"
                id="placa" placeholder="Digite aqui">
            </div>
          </div>
          <div class="col-12 col-sm-6">
            <div class="form-group">
              <label for="renavam">Renavam *</label>
              <input type="text" class="form-control" name="renavam" [(ngModel)]="forms.availability.renavam" maxlength="15"
                id="renavam" placeholder="Digite aqui">
            </div>
          </div>
          <div class="col-12 text-right">
            <button (click)="availabilityStock()" class="btn btn-primary">
              Consultar</button>
          </div>
        </div> 
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modal-send-nf">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Enviar Nota Fiscal Entrada</h2>
        <button (click)="closeModal('modal-send-nf')" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="invoice-key">Chave da nota fiscal</label>
              <input type="text" [(ngModel)]="forms.sendNF.chaveNotaFiscal" maxlength="44" class="form-control"
                id="invoice-key" name="invoice-key" placeholder="Digite aqui">
            </div> 
          </div>
          <div class="col-12 text-right">
            <button (click)="sendNF()" class="btn btn-primary">
              Enviar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


<div class="modal" id="modal-send-signature-atpv">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Enviar Assinatura ATPV</h2>
        <button (click)="closeModal('modal-send-nf')" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12 col-sm-12"> 
               
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="type-signature" id="type-signature1" value="CRV" checked="checked" [(ngModel)]="forms.sendATPV.atpvPapelMoedaProprioPunho" > 
                  <label class="form-check-label" for="type-signature1">
                    CRV em papel moeda - próprio punho
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" name="type-signature" id="type-signature2" value="ATPVe"  [(ngModel)]="forms.sendATPV.atpveAssinadoDeProprioPunho"   >
                  <label class="form-check-label" for="type-signature2">
                    ATPVe - próprio punho
                  </label>
                </div> 
          </div>
          <div class="col-12 col-sm-12">
            <div class="input-group">
              <div class="custom-file">
                <label for="file-upload" class="custom-file-label">{{getFileName()}} <span
                    class="mandatory">*</span></label>
                <input type="file" id="file-upload" class="custom-file-input" (change)="fileChange($event)">
              </div>
            </div>
          </div>
          <div class="col-12 text-right">
            <button (click)="sendATPV()" class="btn btn-primary">
              Enviar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
