<div class="page-title">
  <div class="title-env">
    <h1 class="title">Upload de certificado</h1>
  </div>
</div>

<div class="panel panel-default panel-border">
  <div class="panel-heading">
    Certificado
  </div>
  <form role="form">
    <div class="panel-body">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="input-group">
            <div class="custom-file">
              <label for="file-upload" class="custom-file-label">{{getFileName()}} <span
                  class="mandatory">*</span></label>
              <input type="file" id="file-upload" class="custom-file-input" (change)="fileChange($event)">
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="password">Senha <span class="mandatory">*</span></label>
            <input type="password" class="form-control" name="password" [(ngModel)]="forms.main.password" id="password"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <loading-button (click)="save()" [btnClass]="'btn btn-primary'" [isLoading]="buttons.main">
            Salvar
          </loading-button>
        </div>
      </div>
    </div>
  </form>
</div>