<div class="page-title">
  <div class="title-env">
    <h1 class="title">Autorizar transferência</h1>
  </div>
</div>

<div class="panel panel-default panel-border" *permission="['VeiculoZero-AutorizarTransferencia']">
  <div class="panel-heading">
    Informações da trasferência
  </div>
  <form role="form">
    <div class="panel-body">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="cnpj">CNPJ do estabelecimento de destino</label>
            <input type="text" class="form-control" name="cnpj" id="cnpj"
              [(ngModel)]="forms.main.cnpjEstabelecimentoDestino" mask="00.000.000/0000-00" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="purchase-value">Valor da nota</label>
            <input type="text" [(ngModel)]="forms.main.valorVenda" class="form-control" id="purchase-value"
              name="purchase-value" placeholder="Digite aqui" currencyMask
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <loading-button (click)="save()" [btnClass]="'btn btn-primary'" [isLoading]="buttons.main">
        Salvar
      </loading-button>
    </div>
  </form>
</div>