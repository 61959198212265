<div class="page-title">
  <div class="title-env">
    <h1 class="title">Alterar senha</h1>
  </div>
</div>

<div class="panel panel-default panel-border">
  <div class="panel-heading">
    Informações da trasferência
  </div>
  <form role="form">
    <div class="panel-body">
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="name">Nome completo</label>
            <input [(ngModel)]="name" disabled type="text" class="form-control" name="name" id="name"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="actualPassword">Senha atual <span class="mandatory">*</span></label>
            <input [(ngModel)]="forms.main.senhaAtual" type="password" class="form-control" name="actualPassword"
              id="actualPassword" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="newPassword">Nova senha <span class="mandatory">*</span></label>
            <input [(ngModel)]="forms.main.senhaNova" type="password" class="form-control" name="newPassword"
              id="newPassword" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="confirmPassword">Confirmar nova senha <span class="mandatory">*</span></label>
            <input [(ngModel)]="confirmPassword" type="password" class="form-control" name="confirmPassword"
              id="confirmPassword" placeholder="Digite aqui">
          </div>
        </div>
        <loading-button [isLoading]="buttons.main" [btnClass]="'btn btn-primary'" (click)="save()">Salvar
        </loading-button>
      </div>
    </div>
  </form>
</div>