<div class="page-title">
  <div class="title-env">
    <h1 class="title">Confirmar trasferência</h1>
  </div>
</div>

<div class="panel panel-default panel-border" *permission="['VeiculoZero-AutorizarTransferencia']">
  <div class="panel-heading">
    Informações da trasferência
  </div>
  <form role="form">
    <div class="panel-body">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="invoice-key">Chave da nota fiscal <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="invoice-key" id="invoice-key"
              [(ngModel)]="forms.main.chaveNotaFiscal" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="authorization-id">Id da autorização <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="authorization-id" id="authorization-id"
              [(ngModel)]="forms.main.idAutorizacao" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="transfer-date">Data da trasferência <span class="mandatory">*</span></label>
            <input type="text" mask="00/00/0000" [(ngModel)]="dataTransferenciaEstoque" class="form-control"
              id="transfer-date" name="transfer-date" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="measurement-date">Data e hora de medição do hodômetro <span class="mandatory">*</span></label>
            <input type="text" mask="00/00/0000 00:00" [(ngModel)]="dataHoraMedicaoHodometro" class="form-control"
              id="measurement-date" name="measurement-date" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="hodometer-mileage">Quilometragem do hodômetro <span class="mandatory">*</span></label>
            <div class="input-group">
              <input type="number" [(ngModel)]="forms.main.quilometragemHodometro" class="form-control"
                id="hodometer-mileage" name="hodometer-mileage" aria-describedby="basic-addon"
                placeholder="Digite aqui">
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon" style="height: 100%;">km</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="purchase-value">Valor da compra <span class="mandatory">*</span></label>
            <input type="text" [(ngModel)]="forms.main.valorCompra" class="form-control" id="purchase-value"
              name="purchase-value" placeholder="Digite aqui" currencyMask
              [options]="{ prefix: 'R$ ', thousands: '.', decimal: ',' }">
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <loading-button (click)="save()" [btnClass]="'btn btn-primary'" [isLoading]="buttons.main">
        Salvar
      </loading-button>
    </div>
  </form>
</div>