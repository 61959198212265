<div class="page-title">
  <div class="title-env">
    <h1 class="title">Estoque Usado | Cadastrar Veículo Próprio</h1>
  </div>
</div>

<div class="panel panel-default panel-border">
  <div class="panel-heading">
    Informações do veículo
  </div>
  <form role="form">
    <div class="panel-body">
      <div class="row"> 
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="description">Descrição *</label>
            <input type="text" class="form-control" name="description" [(ngModel)]="forms.main.descricao" maxlength="50"
              id="description" placeholder="Digite aqui">
          </div>
        </div> 
        <div class="col-12 col-sm-3">
          <div class="form-group">
            <label for="manufacture-year">Ano de fabricação</label>
            <input type="number" mask="0000" class="form-control" name="manufacture-year"
              [(ngModel)]="forms.main.anoFabricacao" id="manufacture-year" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-3">
          <div class="form-group">
            <label for="model-year">Ano do modelo</label>
            <input type="number" mask="0000" class="form-control" name="model-year" [(ngModel)]="forms.main.anoModelo"
              id="model-year" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="stock-date">Data de entrada no estoque *</label>
            <input type="text" mask="00/00/0000" [(ngModel)]="dataEntradaEstoque" class="form-control" id="stock-date"
              name="stock-date" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="measurement-date">Data e hora de medição do hodômetro *</label>
            <input type="text" mask="00/00/0000 00:00" [(ngModel)]="dataHoraMedicaoHodometro" class="form-control"
              id="measurement-date" name="measurement-date" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="hodometer-mileage">Quilometragem do hodômetro</label>
            <div class="input-group">
              <input type="number" [(ngModel)]="forms.main.quilometragemHodometro" class="form-control"
                id="hodometer-mileage" name="hodometer-mileage" aria-describedby="basic-addon"
                placeholder="Digite aqui">
              <div class="input-group-append">
                <span class="input-group-text" id="basic-addon" style="height: 100%;">km</span>
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-3">
          <div class="form-group">
            <label for="tipoCrv">Tipo CRV *</label>
             
              <select class="form-control" [(ngModel)]="forms.main.tipoCrv" name="tipoCrv" id="tipoCrv">
                <option selected value="null">Selecione uma opção</option>
                <option value="AZUL">AZUL</option>
                <option value="BRANCO">BRANCO</option>
                <option value="DIGITAL">DIGITAL</option>
                <option value="VERDE">VERDE</option>
              </select>
          </div>
        </div> 
        <div class="col-12 col-sm-3">
          <div class="form-group">
            <label for="numeroCrv">Numero CRV *</label>
            <input type="text" class="form-control" name="numeroCrv" [(ngModel)]="forms.main.numeroCrv" maxlength="12"
              id="numeroCrv" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-3">
          <div class="form-group">
            <label for="codigoSegurancaCrv">Código Segurança CRV *</label>
            <input type="text" class="form-control" name="codigoSegurancaCrv" [(ngModel)]="forms.main.codigoSegurancaCrv" maxlength="5"
              id="codigoSegurancaCrv" placeholder="Digite aqui">
          </div>
        </div> 
        <div class="col-12 col-sm-3">
          <div class="form-group">
            <label for="placa">Placa *</label>
            <input type="text" class="form-control" mask="ZZZ-ZZZZ" name="placa" [(ngModel)]="forms.main.placa" maxlength="8"
              id="placa" placeholder="Digite aqui">
          </div>
        </div> 
        
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="renavam">Renavam *</label>
            <input type="text" class="form-control" name="renavam" [(ngModel)]="forms.main.renavam" maxlength="11"
              id="renavam" placeholder="Digite aqui">
          </div>
        </div>   
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <loading-button (click)="save()" [btnClass]="'btn btn-primary'" [isLoading]="buttons.main">
        Salvar
      </loading-button>
    </div>
  </form>
</div>