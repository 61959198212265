<div class="page-title">
  <div class="title-env">
    <h1 class="title" *ngIf="!owner">Proprietários | Cadastrar Proprietário</h1>
    <h1 class="title" *ngIf="owner">Proprietários | Editar Proprietário</h1>
  </div>
</div>

<div class="panel panel-default panel-border" *permission="['Proprietario-Cadastrar']">
  <div class="panel-heading">
    Informações do proprietário
  </div>
  <form role="form">
    <div class="panel-body">
      <div class="row">
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="name">Nome completo <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="name" id="name" [(ngModel)]="forms.main.nome"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="cpf-cnpj">CPF/CNPJ<span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="cpf-cnpj" id="cpf-cnpj" [(ngModel)]="forms.main.cpfcnpj"
               maxlength="18" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="email">Email <span class="mandatory">*</span></label>
            <input type="email" class="form-control" name="email" id="email" [(ngModel)]="forms.main.email"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="phone">Telefone <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="phone" id="phone" [(ngModel)]="forms.main.telefone"
              mask="(00) 0000-0000" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="cel">Celular <span class="mandatory">*</span></label>
            <input type="text" class="form-control" name="cel" id="cel" [(ngModel)]="forms.main.celular"
              mask="(00) 0 0000-0000" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="juridic-type">Tipo de pessoa (PF/PJ) <span class="mandatory">*</span></label>
            <select class="form-control" [(ngModel)]="forms.main.tipoPessoa" id="juridic-type" name="juridic-type">
              <option selected value="null">Selecione uma opção</option>
              <option name="juridic-type" id="juridic-type" value="F">Física</option>
              <option name="juridic-type" id="juridic-type" value="J">Jurídica</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="panel-heading">
      Endereço
    </div>
    <div class="panel-body">
      <div class="row">
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="address-zipcode">CEP <span class="mandatory">*</span></label>
            <input type="text" mask="00000-000" [(ngModel)]="forms.main.cep" class="form-control" id="address-zipcode"
              name="address-zipcode" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="address-street">Endereço <span class="mandatory">*</span></label>
            <div class="input-group">
              <input type="text" [(ngModel)]="forms.main.endereco" class="form-control" id="address-street"
                name="address-street" placeholder="Digite aqui">
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-4">
          <div class="form-group">
            <label for="address-number">Número <span class="mandatory">*</span></label>
            <input type="text" [(ngModel)]="forms.main.enderecoNum" class="form-control" id="address-number"
              name="address-number" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="address-complement">Complemento <span class="mandatory">*</span></label>
            <input type="text" [(ngModel)]="forms.main.enderecoComp" class="form-control" id="address-complement"
              name="address-complement" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="address-neighborhood">Bairro <span class="mandatory">*</span></label>
            <input type="text" [(ngModel)]="forms.main.bairro" class="form-control" id="address-neighborhood"
              name="address-neighborhood" placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="address-state">Estado <span class="mandatory">*</span></label>
            <select class="form-control" [(ngModel)]="selectedState" (change)="getCity()" name="address-state"
              id="address-state">
              <option selected value="null">Selecione um estado</option>
              <option *ngFor="let state of states" name="address-state" id="address-state" [value]=state>
                {{state}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-12 col-sm-6">
          <div class="form-group">
            <label for="address-city">Munícipio <span class="mandatory">*</span></label>
            <span *ngIf="citiesLoading" class="spinner-border spinner-border-sm" style="margin-left: 10px;"
              role="status" aria-hidden="true"></span>
            <select class="form-control" [disabled]="citiesLoading" [(ngModel)]="forms.main.codigoMunicipio"
              name="address-city" id="address-city">
              <option selected value="null">Selecione um município</option>
              <option *ngFor="let city of cities" name="address-city" id="address-city" [value]=city.id>
                {{city.nome}}
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end">
      <loading-button (click)="owner ? edit() : save()" [btnClass]="'btn btn-primary'" [isLoading]="buttons.main">
        Salvar
      </loading-button>
    </div>
  </form>
</div>