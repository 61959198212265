<div class="page-title">
  <div class="row">
    <div class="col-6">
      <div class="title-env">
        <h1 class="title">Consulta veículos zero km</h1>
      </div>
    </div>
  </div>
</div>

<div class="panel panel-default panel-border">
  <div class="panel-heading">
    Filtrar veículos
  </div>
  <div class="panel-body">
    <form role="form">
      <div class="row">
        <div class="col-sm-4 col-12">
          <div class="form-group">
            <label for="chassi">Chassi</label>
            <input type="text" class="form-control" name="chassi" [(ngModel)]="forms.filter.chassi" id="chassi"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-sm-4 col-12">
          <div class="form-group">
            <label for="plate">Placa</label>
            <input type="text" class="form-control" name="plate" [(ngModel)]="forms.filter.placa" id="plate"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-sm-4 col-12">
          <div class="form-group">
            <label for="renavam">Renavam</label>
            <input type="text" class="form-control" name="renavam" [(ngModel)]="forms.filter.renavam" id="renavam"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-sm-4 col-12">
          <div class="form-group">
            <label for="status">Status</label>
            <select class="form-control" [(ngModel)]="forms.filter.status" name="status" id="status">
              <option value="null">Selecione um status</option>
              <option *ngFor="let status of statuses" name="status" id="status" [value]=status.value>
                {{status.name}}
              </option>
            </select>
          </div>
        </div>
        <div class="col-sm-4 col-12">
          <div class="form-group">
            <label for="period-start">Período inicial</label>
            <input type="text" class="form-control" name="period-start" [(ngModel)]="dataInicio" id="period-start"
              placeholder="Digite aqui" mask="00/00/0000">
          </div>
        </div>
        <div class="col-sm-4 col-12">
          <div class="form-group">
            <label for="period-end">Período final</label>
            <input type="text" class="form-control" name="period-end" [(ngModel)]="dataFim" id="period-end"
              placeholder="Digite aqui" mask="00/00/0000">
          </div>
        </div>
        <div class="col-12">
          <div class="d-flex justify-content-end">
            <loading-button [btnClass]="'btn btn-primary'" (click)="openCheckStockModal()" style="margin-right: 18px;"
              [isLoading]="buttons.main">
              Atualizar estoque
            </loading-button>
            <loading-button [btnClass]="'btn btn-primary'" (click)="searchPendingCars()" style="margin-right: 18px;"
              [isLoading]="buttons.main">
              Carregar novos veículos
            </loading-button>
            <loading-button (click)="search(1)" [btnClass]="'btn btn-primary'" [isLoading]="buttons.main">
              Filtrar</loading-button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="panel-body" *permission="['VeiculoZero-Listar']">
  <div class="row">
    <div class="col-12">
      <table class="table">
        <thead>
          <tr>
            <th>Chassi</th>
            <th>Veículo</th>
            <th>Placa</th>
            <th>Data</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let vehicle of vehicles">
            <td>{{vehicle.chassi}}</td>
            <td>{{vehicle.veiculo}}</td>
            <td>{{vehicle.placa}}</td>
            <td>{{getVehicleDate(vehicle.dataCadastro)}}</td>
            <td>{{vehicle.status}}</td>
            <td class="d-flex justify-content-center" *ngIf="vehicle.proximoStatus.length > 0">
              <div class="btn-group">
                <loading-button type="button" [btnClass]="'btn btn-primary dropdown-toggle'" [isLoading]="buttons.main"
                  data-toggle="dropdown">
                  Ações
                </loading-button>
                <ul class="dropdown-menu" role="menu">
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'EntradaEstoque')">
                    <a class="dropdown-item" (click)="approve(vehicle.codigo)"
                      *permission="['VeiculoZero-EntradaEstoque']">Entrada no estoque</a>
                  </li>
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'AutorizacaoTransferencia')">
                    <a class="dropdown-item" [routerLink]="['/car/authorize-transfer/' + vehicle.codigo]"
                      routerLinkActive="router-link-active"
                      *permission="['VeiculoZero-AutorizarTransferencia']">Autorizar transferência</a>
                  </li>
                  <li *ngIf="vehicle.proximoStatus.includes('Rejeicao')">
                    <a class="dropdown-item" (click)="openModalRejection(vehicle.codigo)"
                      *permission="['VeiculoZero-Rejeicao']">Rejeitar veículo</a>
                  </li>
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'DevolucaoMontadora')">
                    <a class="dropdown-item" (click)="openModalDevolution(vehicle.codigo)"
                      *permission="['VeiculoZero-DevolucaoMontadora']">Devolver</a>
                  </li>
                  <li *ngIf="vehicle.proximoStatus.includes('CancelamentoAutorizacaoTransferencia')">
                    <a class="dropdown-item" (click)="cancelAuthTransfer(vehicle.codigo)"
                      *permission="['VeiculoZero-CancelarAutorizarTransferencia']">Cancelar transferência</a>
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'CancelamentoEntradaEstoque')">
                    <a class="dropdown-item" (click)="openModalCancelEntryStock(vehicle.codigo)"
                      *permission="['VeiculoZero-CancelarEntradaEstoque']">Cancelar entrada
                      estoque</a>
                  </li>
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'CancelamentoRejeicao')">
                    <a class="dropdown-item" (click)="openModalCancelRejection(vehicle.chassi)"
                      *permission="['VeiculoZero-CancelarRejeicao']">Cancelar rejeição</a>
                  </li>
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'SaidaEstoque')">
                    <a class="dropdown-item" [routerLink]="['/car/exit-stock/' + vehicle.codigo]"
                      *permission="['VeiculoZero-SaidaEstoque']">Saída estoque</a>
                  </li>
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'CancelaSaidaEstoque')">
                    <a class="dropdown-item" (click)="openModalCancelExitStock(vehicle.codigo)"
                      *permission="['VeiculoZero-CancelarSaidaEstoque']">Cancelamento saída
                      estoque</a>
                  </li>
                  <li *ngIf="verifyStatus(vehicle.proximoStatus, 'ConsultaPdfATPV')"> 
                    <a class="dropdown-item" (click)="consultaPdfATPV(vehicle.codigo)">ATPV</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <div class="col-12" *ngIf="vehicles.length > 0">
        <app-loading-pagination-button [isLoading]="buttons.main" (click)="search(forms.filter.page + 1)">
        </app-loading-pagination-button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modal-devolution">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Devolução de veículo</h2>
        <button (click)="closeModal('modal-devolution')" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="devolutionDate">Data da devolução</label>
              <input [(ngModel)]="forms.devolution.dataDevolucao" class="form-control" mask="00/00/0000" type="text"
                name="devolutionDate" id="devolutionDate">
            </div>
          </div>
          <div class="col-12 text-right">
            <button (click)="giveBack()" class="btn btn-primary">
              Confirmar devolução</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modal-rejection">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Rejeitar veículo</h2>
        <button (click)="closeModal('modal-rejection')" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <h3 class="mb-4">Deseja realmente rejeitar o veículo?</h3>
        <button class="btn btn-primary" (click)="reject()">Confirmar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modal-cancel-entry-stock">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Cancelar entrada de estoque</h2>
        <button (click)="closeModal('modal-cancel-entry-stock')" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <h3 class="mb-4">Deseja realmente cancelar a entrada de estoque deste veículo?</h3>
        <button class="btn btn-primary" (click)="cancelEntryStock()">Confirmar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modal-cancel-exit-stock">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Cancelar saída de estoque</h2>
        <button (click)="closeModal('modal-cancel-exit-stock')" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <h3 class="mb-4">Deseja realmente cancelar a saída de estoque deste veículo?</h3>
        <button class="btn btn-primary" (click)="cancelExitStock()">Confirmar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modal-cancel-auth-transfer">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Cancelar autorização de transferência</h2>
        <button (click)="closeModal('modal-cancel-auth-transfer')" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <h3 class="mb-4">Deseja realmente cancelar a autorização de transferência deste veículo?</h3>
        <button class="btn btn-primary" (click)="cancelAuthTransfer()">Confirmar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modal-cancel-rejection">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Cancelar rejeição</h2>
        <button (click)="closeModal('modal-cancel-rejection')" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <h3 class="mb-4">Deseja realmente cancelar a rejeição deste veículo?</h3>
        <button class="btn btn-primary" (click)="cancelRejection()">Confirmar</button>
      </div>
    </div>
  </div>
</div>

<div class="modal" id="modal-check-stock">
  <div class="modal-dialog">
    <div class="modal-content" style="color: #000;">
      <div class="modal-header">
        <h2 class="modal-title">Atualizar estoque</h2>
        <button (click)="closeModal('modal-check-stock')" type="button" class="close" data-dismiss="modal"
          aria-hidden="true">&times;</button>
      </div>
      <div class="modal-body">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="chassi">Chassi</label>
              <input [(ngModel)]="forms.check.chassi" class="form-control" type="text" name="chassi" id="chassi">
            </div>
          </div>
          <div class="col-12 text-right">
            <button (click)="checkStock()" class="btn btn-primary">
              Consultar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>