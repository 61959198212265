import { HttpClient } from "@angular/common/http"
import { Injectable } from "@angular/core"
import { Observable } from "rxjs"
import { finalize, map } from "rxjs/operators"
import { IResponse, IUser, IUserClient, IUserClientDetails, IUserClientFilter, IUserClientForm, IUserClientPasswordForm } from "../types"
import { BaseService } from "./base.service"

@Injectable({
  providedIn: 'root'
})

export class UserClientService {

  constructor(
    private baseService: BaseService,
    private http: HttpClient,
  ) { }

  list(filter: IUserClientFilter): Observable<IUserClient[]> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/UsuarioCliente/GetAll`, filter, options)
      .pipe(
        map((res: IUserClient[]) => res),
        finalize(() => 'Completed!')
      )
  }

  get(codigo: string, codigoCliente: number): Observable<IUserClientDetails> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/UsuarioCliente/Details`, { codigo, codigoCliente }, options)
      .pipe(
        map((res: IUserClientDetails) => res),
        finalize(() => 'Completed!')
      )
  }

  save(user: IUserClientForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/UsuarioCliente/Create`, user, options)
  }

  update(user: IUserClientForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/UsuarioCliente/Update`, user, options)      
  }

  updatePassword(form: IUserClientPasswordForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/UsuarioCliente/UpdateSenha`, form, options)
  }

}
