import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { ClientService } from 'src/app/services/client.service';
import { ICLient } from 'src/app/types';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit {

  public buttons = {
    main: false
  }

  public forms = {
    filter: {
      codigoCliente: 0,
      pageSize: 10,
      page: 1,
      fantasia: '',
      razao_Social: ''
    }
  }

  public clients: ICLient[] = []

  constructor(
    private clientService: ClientService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.search(1)
  }

  public search(page: number): void {
    this.buttons.main = !this.buttons.main
    this.forms.filter.page = page;
    this.forms.filter.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    if (page === 1) {
      this.clients = []
    }
    this.clientService.getAll(this.forms.filter).subscribe(
      (res) => {
        this.buttons.main = !this.buttons.main
        res.forEach(client => this.clients.push(client))
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  register() {
    this.router.navigate(['/client/register'])
  }

  edit(clientId: Number) {
    this.router.navigate([`/client/register/${clientId}`])
  }

  newUser(clientId: number) {
    this.router.navigate(['/user/register'], { queryParams: { clientId } })
  }
}
