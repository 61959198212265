import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserClientService } from 'src/app/services/user-client.service';
import { AuthService } from 'src/app/services/auth.service';
import { IUserClient } from 'src/app/types';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  public buttons = {
    main: false
  }

  public forms = {
    filter: {
      nome: '',
      login: '',
      codigoCliente: 0,
      pageSize: 10,
      page: 1,
      ativo: true
    }
  }

  public users: IUserClient[] = []

  constructor(
    private userClientService: UserClientService,
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.search(1)
  }

  search(page: number) {
    this.buttons.main = !this.buttons.main 
    this.forms.filter.codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.forms.filter.page = page;
    if (page === 1) {
      this.users = []
    }
    this.userClientService.list(this.forms.filter).subscribe(
      (users) => {
        users.forEach(user => this.users.push(user))
        this.buttons.main = !this.buttons.main
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  edit(userId: number) {
    this.router.navigate([`/user/register/${userId}`])
  }
}
