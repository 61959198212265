import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { finalize, map } from "rxjs/operators";
import { IAuthForm, ICancelAuthTransfer, ICancelRejection, ICar, ICheckStockForm, IDevolutionVehicle, IExitStockForm, IPendingCarsForm, IRejectVehicle, IResponse, IStockForm, ITransferForm, IZeroCarFilter,IPdfATPVForm, ICarFilter, IStockUsedForm, IAvailabilityStockForm, IStockUsedOwnForm, ISendNF, ITermEntry, IExitStockUsedForm, ISendATPVASignature, ICarSearch} from "../types";
import { BaseService } from "./base.service";

@Injectable({
  providedIn: 'root'
})

export class CarService {

  constructor(
    private baseService: BaseService,
    private http: HttpClient,
  ) { }

  listZeroCars(filter: IZeroCarFilter): Observable<ICar[]> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/consulta-veiculos-zero-km`, filter, options)
      .pipe(
        map((res: ICar[]) => res),
        finalize(() => 'Completed!')
      )
  }

  rejectZeroCar(data: IRejectVehicle): Observable<any> {
    const options = this.baseService.getHttpOptions()
    console.log(data);
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/rejeicoes_zero_km`, data, options)
  }

  devolutionZeroCar(data: IDevolutionVehicle): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/devolucao_montadora_zero_km`, data, options)
  }

  approveEntryStock(data: IStockForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/entradas_estoque_zero_km`, data, options)
  }

  authorizeTransfer(data: IAuthForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/autorizar_transferencia_veiculo_zero_km`, data, options)
  }

  confirmTransfer(data: ITransferForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/confirmar_transferencia_veiculo_zero_km`, data, options)
  }

  listPendingCars(data: IPendingCarsForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/consulta-veiculos-zero-km-pendentes-pre-cadastro`, data, options)
  }

  cancelAuthTransfer(data: ICancelAuthTransfer): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/cancelamento_autorizacao_transferencia_veiculo_zero_km`, data, options)
  }

  cancelRejectZeroCar(data: ICancelRejection): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/cancelamento_rejeicao_veiculo_zero_km`, data, options)
  }

  cancelEntryStock(data: IRejectVehicle): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/cancelamento_entrada_estoque`, data, options)
  }

  exitStock(data: IExitStockForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/saidas_estoque_zero_km`, data, options)
  }

  cancelExitStock(data: IRejectVehicle): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/cancelamento_saida_estoque_zero_km`, data, options)
  }

  checkStock(data: ICheckStockForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/estoque_atualiza`, data, options)
  }
  availabilityStock(data: IAvailabilityStockForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/aptidao_estoque`, data, options)
  }
  
  pdfATPV(data: IPdfATPVForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/pdf_atpv`, data, options)
  }

  pdfCRLVE(data: IPdfATPVForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/crlve`, data, options)
  }

  pdfCRLVESeguranca(data: IPdfATPVForm): Observable<any> {  
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/crlve_seguranca`, data, options)
  }

  listCars(filter: ICarFilter): Observable<ICar[]> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/consulta-veiculos`, filter, options)
      .pipe(
        map((res: ICar[]) => res),
        finalize(() => 'Completed!')
      )
  }
  
  approveEntryStockUsed(data: IStockUsedForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/entradas_estoque`, data, options)
  }
  
  approveEntryStockOwnUsed(data: IStockUsedOwnForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/entradas_estoque`, data, options)
  }
  
  sendATPVASignature(data: ISendATPVASignature): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/atpv_assinatura`, data, options)
  }

  sendNF(data: ISendNF): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/enviar_nota_fiscal`, data, options)
  }

  
  termEntry(data: ITermEntry): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/termo_entrada_estoque`, data, options)
  }

  
  termExit(data: ITermEntry): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/termo_saida_estoque`, data, options)
  }

  
  exitStockUsed(data: IExitStockUsedForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/saidas_estoque`, data, options)
  }

  
  cancelExitStockUsed(data: IRejectVehicle): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/cancelamento_entrada_estoque`, data, options)
  }

  
  searchATPVSignature(data: IPdfATPVForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/consulta_atpv_assinatura`, data, options)
  }

  
  authorizeTransferUsed(data: IAuthForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/autorizar_transferencia_veiculo`, data, options)
  }
  cancelAuthTransferUsed(data: ICancelAuthTransfer): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Movimentacao/cancelamento_autorizacao_transferencia_veiculo`, data, options)
  }

  detailsCar(data: ICarSearch): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Veiculo/Details`, data, options)
  }

    
}