import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router'; 
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { RelatorioService } from 'src/app/services/relatorio.service';
import { IRelatorioProdutividade } from 'src/app/types';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-rel-produtividade',
  templateUrl: './rel-produtividade.component.html',
  styleUrls: ['./rel-produtividade.component.scss']
})
export class RelProdutividadeComponent implements OnInit {
 
  public isAdmin = false
  public buttons = {
    main: false
  }

  public forms = {
    filter: { 
      dataInicial: '',
      dataFinal: '',
      codigoUsuario: 0
    }
  }
  public dataInicial = moment().subtract(10, 'days').format('DD/MM/YYYY')
  public dataFinal = moment().format('DD/MM/YYYY')

  public rel: IRelatorioProdutividade[] = []

  constructor(
    private relatorioService: RelatorioService,
    private authService: AuthService,
    private router: Router
  ) {        
    this.isAdmin = this.authService.getUser().isAdmin;    
  }

  ngOnInit(): void {
    
  }

  public relatorio(): void {
    this.buttons.main = !this.buttons.main     

    this.forms.filter.dataInicial = moment(this.dataInicial, 'DD/MM/YYYY').toISOString().split('T')[0]
    this.forms.filter.dataFinal = moment(this.dataFinal, 'DD/MM/YYYY').toISOString().split('T')[0]
    this.relatorioService.relProdutividade(this.forms.filter).subscribe(
      (res) => {
        //console.log(res)
        this.showExcel(res, 'relProdutividade');
        this.buttons.main = !this.buttons.main 
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        }) 
      }
    ) 
  }

  showExcel(fileBase64:string, file:string) {
    const linkSource = 'data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,' + fileBase64;
    const downloadLink = document.createElement("a");
    const fileName = file+".xlsx";

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}
}
