<footer class="main-footer fixed footer-type-1">
  <div class="footer-inner">
    <!-- Add your copyright text here -->
    <div class="footer-text">
      &copy; {{actualYear}}
      <strong>Via Renave</strong>
    </div>
    <!-- Go to Top Link, just add rel="go-top" to any link to add this functionality -->
    <div class="go-up">
      <a rel="go-top">
        <i class="fa-angle-up"></i>
      </a>
    </div>
  </div>
</footer>