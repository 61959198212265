import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-pagination-button',
  templateUrl: './loading-pagination-button.component.html',
  styleUrls: ['./loading-pagination-button.component.scss']
})
export class LoadingPaginationButtonComponent implements OnInit {
  @Input() isLoading = false;

  constructor() { }

  ngOnInit(): void {
  }

}
