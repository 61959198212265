import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { VendorService } from 'src/app/services/vendor.service';
import { IVendor } from 'src/app/types';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vendor-register',
  templateUrl: './vendor-register.component.html',
  styleUrls: ['./vendor-register.component.scss']
})
export class VendorRegisterComponent implements OnInit {

  public forms = {
    main: {
      codigo: 0,
      nome: '',
      cnpj: '',
      nomeUsuario:''
    }
  }

  public buttons = {
    main: false
  }
 
  public vendor: IVendor = null

  constructor(
    private authService: AuthService,
    private vendorService: VendorService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      if (paramMap.get('id')) {
        this.get(parseInt(paramMap.get('id')));
      }
    })
  }

  get(id: number) {
    this.buttons.main = !this.buttons.main
    this.vendorService.get({ codigo: id}).subscribe(
      (vendor) => {
        this.buttons.main = !this.buttons.main
        this.forms.main = vendor
        this.vendor = vendor        
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Ocorreu um erro inesperado!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  save() {
    if (this.validateInputs()) {
      this.buttons.main = !this.buttons.main
      this.vendorService.save(this.forms.main).subscribe(
        (res) => {
          console.log(res)
          this.buttons.main = !this.buttons.main
          this.router.navigateByUrl('/vendor/list')
          Swal.fire({
            title: 'Sucesso!',
            text: 'Parceiro cadastrado com sucesso',
            icon: 'success'
          })
        },
        (err) => {
          console.log(err)
          this.buttons.main = !this.buttons.main
          Swal.fire({
            title: 'Ocorreu um erro inesperado!',
            text: err.error,
            icon: 'error'
          })
        }
      )
    }
  }

  edit() {
    if (this.validateInputs()) {
      this.buttons.main = !this.buttons.main
      this.vendorService.edit(this.forms.main).subscribe(
        (res) => {
          console.log(res)
          this.buttons.main = !this.buttons.main
          this.router.navigateByUrl('/vendor/list')
          Swal.fire({
            title: 'Sucesso!',
            text: res,
            icon: 'success'
          })
        },
        (err) => {
          console.log(err)
          this.buttons.main = !this.buttons.main
          Swal.fire({
            title: 'Ocorreu um erro inesperado!',
            text: err.error,
            icon: 'error'
          })
        }
      )
    }
  }
 

  validateInputs(): boolean {
    if (!this.forms.main.nome || this.forms.main.nome.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o nome do associado',
        icon: 'error'
      })
      return false
    }
    if (!this.forms.main.cnpj || this.forms.main.cnpj.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o cnpj do associado',
        icon: 'error'
      })
      return false
    } 
    return true
  }

}
