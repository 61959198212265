<div class="page-title">
  <div class="row">
    <div class="col-6">
      <div class="title-env">
        <h1 class="title">Usuários</h1>
      </div>
    </div>
  </div>
</div>

<div class="panel panel-default panel-border">
  <div class="panel-heading">
    Filtrar usuários
  </div>
  <div class="panel-body">
    <form role="form">
      <div class="row">
        <div class="col-sm-6 col-12">
          <div class="form-group">
            <label for="nome">Nome</label>
            <input type="text" class="form-control" name="nome" [(ngModel)]="forms.filter.nome" id="nome"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-sm-6 col-12">
          <div class="form-group">
            <label for="email">Email</label>
            <input type="text" class="form-control" name="email" [(ngModel)]="forms.filter.login" id="email"
              placeholder="Digite aqui">
          </div>
        </div>
        <div class="col-12">
          <loading-button (click)="search(1)" class="d-flex justify-content-end" [btnClass]="'btn btn-primary'"
            [isLoading]="buttons.main">
            Filtrar</loading-button>
        </div>
      </div>
    </form>
  </div>
</div>

<div class="panel-body" *permission="['Usuario-Listar']">
  <div class="row">
    <div class="col-12">
      <table class="table">
        <thead>
          <tr>
            <th>Nome</th>
            <th>CPF</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users">
            <td>{{user.nome}}</td>
            <td>{{user.cpf | mask:'000.000.000-00'}}</td> 
            <td>{{user.ativo ? 'Ativo' : 'Inativo'}}</td>
            <td class="d-flex justify-content-center">
              <div class="btn-group">
                <button type="button" class="btn btn-primary dropdown-toggle" data-toggle="dropdown">
                  Ações
                </button>
                <ul class="dropdown-menu" role="menu">
                  <li *permission="['Usuario-Alterar']">
                    <a class="dropdown-item" (click)="edit(user.codigo)">Editar</a>
                  </li>
                </ul>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <app-loading-pagination-button *ngIf="users.length > 0" [isLoading]="buttons.main"
        (click)="search(forms.filter.page + 1)">
      </app-loading-pagination-button>
    </div>
  </div>
</div>