import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserClientService } from 'src/app/services/user-client.service';
import { AuthService } from 'src/app/services/auth.service';
import { IUserClientDetails } from 'src/app/types';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-user-register',
  templateUrl: './user-register.component.html',
  styleUrls: ['./user-register.component.scss']
})
export class UserRegisterComponent implements OnInit {

  public isAdmin = false
  public showPassword = false

  public forms = {
    main: {
      codigo: 0,
      nome: '',
      cpf: '',
      email: '',
      ativo: true,
      codigoGrupo: 0,
      codigoCliente: 0,
    },
    password: {
      senhaAtual: '',
      senhaNova: '',
      confirmPassword: '',
      codigoCliente: 0,
      codigo: 0
    }
  }

  public buttons = {
    main: false,
    password: false
  }

  public user: IUserClientDetails

  constructor(
    private route: ActivatedRoute,
    private authService: AuthService,
    private userClientService: UserClientService
  ) {
    this.isAdmin = this.authService.getUser().isAdmin;    
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(paramMap => {
      if (paramMap.get('id')) {
        this.get(paramMap.get('id'));
      }
    })
    this.route.queryParams.subscribe(params => {
      if (params.clientId) {
        this.forms.main.codigoCliente = params.clientId
      }
    })
  }

  get(id: string) {
    var codigoCliente = this.authService.getUser().selectedClient.codigoCliente
    this.userClientService.get(id, codigoCliente).subscribe(
      (user) => { 
        this.user = user
        this.forms.main = user
        this.forms.password.codigo = user.codigo
        this.forms.password.codigoCliente = user.codigoCliente
      },
      (err) => {
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  save() {
    if (!this.forms.main.nome || this.forms.main.nome.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o nome do usuário',
        icon: 'error'
      })
      return
    }
    if (!this.forms.main.cpf || this.forms.main.cpf.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o cpf do usuário',
        icon: 'error'
      })
      return
    }
    if (!this.forms.main.email || this.forms.main.email.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o email do usuário',
        icon: 'error'
      })
      return
    }
    if (!this.forms.main.codigoGrupo || this.forms.main.codigoGrupo === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o grupo!',
        icon: 'error'
      })
      return false
    }
    this.buttons.main = !this.buttons.main
    this.forms.main.codigoCliente  = this.authService.getUser().selectedClient.codigoCliente
    this.userClientService.save(this.forms.main).subscribe(
      (res) => {
        this.buttons.main = !this.buttons.main
        Swal.fire({
          title: 'Successo',
          text: res,
          icon: 'success'
        }) 
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  update() {
    if (!this.forms.main.nome || this.forms.main.nome.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o nome do usuário',
        icon: 'error'
      })
      return
    }
    if (!this.forms.main.cpf || this.forms.main.cpf.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o cpf do usuário',
        icon: 'error'
      })
      return
    }
    if (!this.forms.main.email || this.forms.main.email.length === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Preencha o email do usuário',
        icon: 'error'
      })
      return
    }
    if (!this.forms.main.codigoGrupo || this.forms.main.codigoGrupo === 0) {
      Swal.fire({
        title: 'Erro!',
        text: 'Por favor preencha o grupo!',
        icon: 'error'
      })
      return false
    }
    this.buttons.main = !this.buttons.main
    this.forms.main.ativo = Boolean(JSON.parse(String(this.forms.main.ativo))); 
    this.userClientService.update(this.forms.main).subscribe(
      (res) => {
        this.buttons.main = !this.buttons.main
        Swal.fire({
          title: 'Successo',
          text: res,
          icon: 'success'
        })
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  updatePassword() {
    if (this.validatePassword()) {
      this.buttons.password = !this.buttons.password;
      this.userClientService.updatePassword(this.forms.password).subscribe(
        (res) => {
          this.buttons.password = !this.buttons.password;
          Swal.fire({
            title: 'Successo',
            text: res,
            icon: 'success'
          })  
        },
        (err) => {
          console.log(err)
          Swal.fire({
            title: 'Erro!',
            text: err.error,
            icon: 'error'
          })
        }
      )
    }
  }

  toggleFieldTextType() {
    this.showPassword = !this.showPassword
  }

  validatePassword(): boolean {
    if (!this.forms.password.senhaNova || (this.forms.password.senhaNova !== this.forms.password.confirmPassword)) {
      alert('A nova senha e a senha de confirmação estão diferentes!')
      return false
    }
    return true
  }

}
