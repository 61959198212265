import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { VendorService } from 'src/app/services/vendor.service';
import { IVendor } from 'src/app/types';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-vendor',
  templateUrl: './vendor.component.html',
  styleUrls: ['./vendor.component.scss']
})
export class VendorComponent implements OnInit {

  public isAdmin = false
  public buttons = {
    main: false
  }

  public forms = {
    filter: {
      pageSize: 10,
      page: 1,
      nome: '',
      cnpj: ''
    }
  }

  public vendors: IVendor[] = []

  constructor(
    private vendorService: VendorService,
    private authService: AuthService,
    private router: Router
  ) {     
    this.isAdmin = this.authService.getUser().isAdmin;    
  }

  ngOnInit(): void {
    this.search(1)
  }

  public search(page: number): void {
    this.buttons.main = !this.buttons.main
    this.forms.filter.page = page;    
    if (page === 1) {
      this.vendors = [];
    }
    this.vendorService.list(this.forms.filter).subscribe(
      (res) => { 
        this.buttons.main = !this.buttons.main
        res.forEach(vendor => this.vendors.push(vendor))
      },
      (err) => {
        this.buttons.main = !this.buttons.main
        console.log(err)
        Swal.fire({
          title: 'Erro!',
          text: err.error,
          icon: 'error'
        })
      }
    )
  }

  register() {
    this.router.navigate(['/vendor/register'])
  }

  edit(vendorId: Number) {
    this.router.navigate([`/vendor/register/${vendorId}`])
  }
  
  getTypeMask(): string {    
    return '00.000.000/0000-00'
  }
}
