import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { finalize, map } from 'rxjs/operators';
import { BaseService } from './base.service';
import { ICertificateForm, IChangePasswordForm, ICLient, IClientFilter, IGetCreditForm, IResponse } from "../types";

@Injectable({
  providedIn: 'root'
})

export class ClientService {

  constructor(
    private baseService: BaseService,
    private http: HttpClient,
  ) { }

  getAll(filter: IClientFilter): Observable<ICLient[]> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Cliente/GetAll`, JSON.stringify(filter), options)
      .pipe(
        map((res: ICLient[]) => res),
        finalize(() => 'Completed!')
      )
  }

  get(id: String): Observable<ICLient> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Cliente/Details`, JSON.stringify({ codigo: id }), options)
      .pipe(
        map((res: ICLient) => res),
        finalize(() => 'Completed!')
      )
  }

  save(client: ICLient): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Cliente/Create`, JSON.stringify(client), options)
  }

  edit(client: ICLient): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Cliente/Update`, JSON.stringify(client), options)
  }

  saveCertificate(data: ICertificateForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Cliente/saveCertificado`, JSON.stringify(data), options)
  }

  getCredits(data: IGetCreditForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/Cliente/GetCredito`, data, options)
  }

  updatePassword(data: IChangePasswordForm): Observable<any> {
    const options = this.baseService.getHttpOptions()
    return this.http.post(`${this.baseService.baseurl}/UsuarioCliente/UpdateSenha`, data, options)
  }
}